import {
  CheckIcon,
  ChevronUpDownIcon,
  MicrophoneIcon,
  TrashIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  deleteVideoApi,
  uploadVideoUrlApi,
} from "../../../services/video.services";
import { toast } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import {
  uploadAudioDataApi,
  uploadFile,
} from "../../../services/upload.services";
import { uploadAudioSummaryApi } from "../../../services/audio.services";
import { useUserStore } from "../../../store/user";
import AlertModal from "../../../components/Alert/AlertModal";

const languageTypes = [
  { id: "ENG", name: "English" },
  { id: "HIN", name: "Hindi" },
  { id: "ARA", name: "Arabic" },
];

export default function LeftSection({ getHateSpeechDataQuery, contentTypes }) {
  const relativeTime = require("dayjs/plugin/relativeTime"); // Import the plugin
  dayjs.extend(relativeTime); // Extend Day.js with the plugin
  const queryClient = useQueryClient();

  const [inputMethod, setInputMethod] = useState("url");
  const [file, setFile] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const fileInputRef = useRef(null);

  const tabs = [
    { name: "Url", ref: "url", current: false },
    { name: "File", ref: "file", current: true },
  ];

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const userDetail = useUserStore((state) => state.userDetail);

  // const [selectedContentType, setSelectedContentType] = useState(
  //   contentTypes[5]
  // );
  const [selectedLanguageType, setSelectedLanguageType] = useState(
    languageTypes[0]
  );

  const onFileChange = (file) => {
    setFile(file);
    const blob = new Blob([file], { type: file.type });
    console.log("file", file);
    setFileLoading(true);
    uploadFile(blob, {
      contentType: file.type,
      ext: `.${file.name.split(".").pop()}`,
    })
      .then((filename) => {
        console.log("File uploaded to: " + filename);
        setFileUrl(filename);
      })
      .finally(() => setFileLoading(false));
  };

  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // post ytb url and get document id
  const uploadVideoUrlMutation = useMutation({
    mutationFn: uploadVideoUrlApi,
    onSuccess: (data) => {
      setOpen(false);
      toast.success(data.msg);
      const currentVideos = queryClient.getQueryData([
        "getHateSpeechSummary",
        contentTypes,
      ]);

      queryClient.setQueryData(
        ["getHateSpeechSummary", contentTypes],
        [...currentVideos, data.data]
      );
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleUploadUrl = () => {
    const payload = {
      url: inputMethod === "url" ? inputValue : fileUrl,
      content_type: contentTypes,
      lang_type: selectedLanguageType.id,
    };

    // console.log("payload", payload);
    uploadVideoUrlMutation.mutate(payload);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const computeStatus = (tasks) => {
    // Initialize counts
    let completedCount = 0;
    let pendingCount = 0;
    let failedCount = 0;

    // Iterate through tasks and count occurrences
    tasks.forEach((task) => {
      switch (task.task_status) {
        case "completed":
          completedCount++;
          break;
        case "pending":
          pendingCount++;
          break;
        case "failed":
          failedCount++;
          break;
        default:
          // Handle unexpected status values
          break;
      }
    });

    if (pendingCount > 0)
      return [
        `${pendingCount} task pending`,
        "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
      ];
    if (failedCount > 0)
      return [
        `${failedCount} task failed`,
        "text-red-800 bg-red-50 ring-red-600/20",
      ];
    if (completedCount > 0)
      return [
        `${completedCount} task completed`,
        "text-green-700 bg-green-50 ring-green-600/20",
      ];
  };

  const handleDeleteVideo = (e, id) => {
    e.preventDefault();
    setIsDeleteModalOpen(true);
    setSelectedVideoId(id);
  };

  const deleteVideoMutation = useMutation({
    mutationFn: deleteVideoApi,
    onSuccess: (data, id) => {
      setIsDeleteModalOpen(false);
      const currentVideos = queryClient.getQueryData([
        "getHateSpeechSummary",
        contentTypes,
      ]);

      queryClient.setQueryData(
        ["getHateSpeechSummary", contentTypes],
        currentVideos.filter((video) => video._id !== id)
      );
    },
    onError: (err) => {
      setIsDeleteModalOpen(false);
      toast.error(err.message);
    },
  });

  const handleDeleteMutation = async () => {
    await deleteVideoMutation.mutate(selectedVideoId);
  };

  // clear the input value when the modal closes
  useEffect(() => {
    if (!open) {
      setFile(null);
      setFileUrl("");
      setInputValue("");
    }
  }, [open]);
  return (
    <>
      <div className="overflow-hidden rounded-lg bg-white shadow  p-6">
        <h2 className="sr-only">Audios</h2>
        <h2 className="truncate text-2xl font-bold text-gray-700">
          Audino Hate Speech AI
        </h2>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">
          Detect hate speech in audio or video files using our AI models.
        </p>

        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8 mt-8">
          {getHateSpeechDataQuery.isLoading ? (
            <>
              {" "}
              {[...Array(6).keys()].map((e) => (
                <div
                  className="flex-auto rounded-md animate-pulse"
                  key={`loadingVideo-${e}`}
                >
                  <div className="w-full h-56 bg-[#70cba2] rounded-md"></div>
                </div>
              ))}{" "}
            </>
          ) : (
            <>
              <button
                type="button"
                className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none"
                onClick={() => setOpen(true)}
              >
                <MicrophoneIcon
                  width={48}
                  color="#9ca3af"
                  className="mx-auto"
                />
                <span className="mt-2 block text-sm font-semibold text-gray-900">
                  Upload an audio/ video
                </span>
              </button>

              {getHateSpeechDataQuery.data?.map((video) => (
                <div
                  key={video._id}
                  className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
                >
                  <div className=" bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-48 flex items-center justify-center">
                    {video?.upload_type === "audio_url" ? (
                      <audio controls autoplay>
                        <source
                          src={`https://storage.googleapis.com/${process.env.REACT_APP_BUCKET_NAME}/${video.audio_path}`}
                          type="audio/mp3"
                        />
                      </audio>
                    ) : video?.upload_type === "video_url" ? (
                      <video width="100%" controls>
                        <source src={video.url} type="video/mp4" />
                        Your browser does not support HTML video.
                      </video>
                    ) : (
                      <iframe
                        src={`https://www.youtube.com/embed/${video.ytb_vid_id}`}
                        frameborder="0"
                        allow="encrypted-media"
                        title="video"
                        className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                      />
                    )}
                  </div>
                  <NavLink
                    to={`/hate-speech-detail?id=${video._id}&ytbId=${video.ytb_vid_id}`}
                  >
                    <div className="flex flex-1 flex-col space-y-2 p-4 pt-1">
                      <div className="flex flex-1 flex-row justify-between items-start gap-2 mt-2">
                        <p
                          className={classNames(
                            computeStatus(video?.tasks)[1],
                            " inline-flex items-center rounded-md px-2 py-1 text-xs font-medium  ring-1 ring-inset"
                          )}
                        >
                          {computeStatus(video?.tasks)[0]}
                          {/* {video?.tasks.length} */}
                        </p>
                        {/* <p className="text-sm text-gray-500">
                          {video?.num_chapterization} chapters generated
                        </p> */}
                      </div>
                      <p className="text-sm text-gray-500 truncate">
                        {/* {video?.task_status === "completed"
                          ? video.summary
                          : video?.task_status === "failed"
                          ? video?.task_error_message
                          : "Hang on we are processing..."} */}
                        {/* {video?.summary
                          ? JSON.parse(video?.summary)['Agenda']
                          : "Hang on we are processing..."} */}
                      </p>
                      <div className="flex">
                        <p className="text-sm italic text-gray-500">
                          Uploaded {dayjs(video.createdAt).fromNow()} by{" "}
                          {video.uploaded_by?.name}
                        </p>
                        {(video.organisation?.owner === userDetail._id ||
                          video.uploaded_by?._id === userDetail._id) && (
                          <div className="w-5 h-5 rounded hover:bg-red-400 bg-white ml-auto flex items-center justify-center">
                            <TrashIcon
                              className="h-4 w-4 hover:text-white text-red-400 cursor-pointer"
                              onClick={(e) => handleDeleteVideo(e, video._id)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </NavLink>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      <Transition.Root
        show={open}
        as={Fragment}
        // afterLeave={() => setInputValue("")}
        appear
      >
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen  p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform rounded-xl bg-white bg-opacity-80 shadow-2xl ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter transition-all p-8">
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-[#70cba2] focus:ring-[#70cba2]"
                    defaultValue={inputMethod}
                    onChange={(event) => setInputMethod(event.target.value)}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name} value={tab.ref}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block mb-4">
                  <nav
                    className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                    aria-label="Tabs"
                  >
                    {tabs.map((tab, tabIdx) => (
                      <div
                        key={tab.name}
                        onClick={() => setInputMethod(tab.ref)}
                        className={classNames(
                          inputMethod === tab.ref
                            ? "text-gray-900"
                            : "text-gray-500 hover:text-gray-700",
                          tabIdx === 0 ? "rounded-l-lg" : "",
                          tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                          "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                        )}
                        aria-current={
                          inputMethod === tab.ref ? "page" : undefined
                        }
                      >
                        <span>{tab.name}</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            inputMethod === tab.ref
                              ? "bg-[#70cba2] "
                              : "bg-transparent",
                            "absolute inset-x-0 bottom-0 h-0.5"
                          )}
                        />
                      </div>
                    ))}
                  </nav>
                </div>

                {inputMethod === "url" && (
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-900 text-opacity-40"
                      aria-hidden="true"
                    />
                    <input
                      className="h-12 w-full bg-white pl-11 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300 sm:text-sm outline-none rounded-md"
                      placeholder="URL of a video or audio"
                      onChange={(event) => setInputValue(event.target.value)}
                    />
                  </div>
                )}
                {inputMethod === "file" && (
                  <div
                    className="flex flex-col items-center justify-cente"
                    onClick={openFileInput}
                    onDragEnter={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const droppedFile = e.dataTransfer.files[0];
                      if (droppedFile) {
                        onFileChange(droppedFile);
                      }
                    }}
                  >
                    <label
                      htmlFor="fileInput"
                      className={`
                              ${
                                file
                                  ? "border-dashed border-[#70cba2] dark:border-[#70cba2] hover:border-[#70cba2]"
                                  : "border-dashed border-gray-300 dark:border-gray-600 hover-border-gray-500"
                              }
                              flex flex-col items-center justify-center w-72 border-2 rounded-lg cursor-pointer bg-white py-4 px-6 
                            `}
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          className="w-8 h-8 mb-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        <p className="mb-2 text-xs text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">
                            {fileLoading
                              ? "Uploading..."
                              : file
                              ? `Selected: ${file.name}`
                              : "Click to upload or drag and drop"}
                          </span>{" "}
                        </p>
                        {!file && (
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            MP3, WAV, WMA, or AAC
                          </p>
                        )}
                      </div>
                      <input
                        id="fileInput"
                        type="file"
                        className="hidden"
                        accept="audio/*"
                        onChange={(e) => onFileChange(e.target.files[0])}
                      />
                    </label>
                  </div>
                )}

                <Listbox
                  value={selectedLanguageType}
                  onChange={setSelectedLanguageType}
                >
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-700 mt-4">
                        Language type
                      </Listbox.Label>
                      <div className="relative mt-1">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6">
                          <span className="block truncate">
                            {selectedLanguageType.name}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {languageTypes.map((langType) => (
                              <Listbox.Option
                                key={langType.id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "bg-[#70cba2] text-white"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={langType}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate"
                                      )}
                                    >
                                      {langType.name}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>

                <button
                  type="button"
                  className="mt-8 mx-auto flex rounded-md border border-transparent bg-[#70cba2] px-8 py-2 text-base font-medium text-white hover:bg-[#5fac89] disabled:cursor-not-allowed"
                  onClick={handleUploadUrl}
                  disabled={uploadVideoUrlMutation.isLoading}
                >
                  {uploadVideoUrlMutation.isLoading ? "Loading..." : "Upload"}
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* confirmation modal */}
      <AlertModal
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        onSuccess={handleDeleteMutation}
        onCancel={() => setIsDeleteModalOpen(false)}
        text={"Are you sure you want to delete this video/audio? "}
        isLoading={deleteVideoMutation.isLoading}
      />
    </>
  );
}
