import React from "react";
import AppBar from "../../components/AppBar/AppBar";
import ListOfUser from "./components/ListOfUser";

function UserPage() {
  return (
    <div className="min-h-full">
      <AppBar />
      <main className="bg-[#e4f5ed] min-h-screen pt-8 pb-8">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <ListOfUser />
        </div>
      </main>
    </div>
  );
}

export default UserPage;
