import { Cog6ToothIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useState, useEffect, Fragment } from "react";
import AppBar from "../../components/AppBar/AppBar";
import { useNavigate } from "react-router";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
// import AlertModal from "../../components/Alert/AlertModal";

import { useOrganizationStore } from "../../store/organizations";
import {
  fetchOrganizationsApi,
  deleteOrganizationApi,
} from "../../services/organization.services";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import CardLoader from "../../components/Loader/CardLoader";
import Footer from "../../components/Footer/Footer";
import AlertModal from "../../components/Alert/AlertModal";
import { Tooltip } from "react-tooltip";

export default function OrganizationsPage() {
  const [currentOrg, setCurrentOrg] = useState(
    localStorage.getItem("audino-ai-org")
  );
  const [deleteOrgId, setdeleteOrgId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  dayjs.extend(relativeTime);
  dayjs.extend(advancedFormat);

  // const setOrganizations = useOrganizationStore((state) => state.setOrgs);
  // const organizations = useOrganizationStore((state) => state.orgs_obj);

  const { data: organizations, isLoading } = useQuery({
    queryKey: ["organizations"],
    enabled: true,
    staleTime: 1000 * 60 * 5,
    queryFn: () => fetchOrganizationsApi({}),
    onSuccess: (data) => {
      // setOrganizations(data);
    },
    onError: (err) => toast.error(err.message),
  });

  const handleOrgClick = (orgSlug) => {
    localStorage.setItem("audino-ai-org", orgSlug);
    toast.success(`Organization updated to ${orgSlug}`);
    setCurrentOrg(orgSlug);
  };
  const handleRemoveOrgClick = () => {
    localStorage.removeItem("audino-ai-org");
    toast.success("Organization updated to personal workspace");
    setCurrentOrg("");
  };

  const handleDeleteOrg = () => {
    delteOrgMutation.mutate({ id: deleteOrgId });
  };

  //delete organization mutation
  const delteOrgMutation = useMutation({
    mutationFn: deleteOrganizationApi,
    onSuccess: (data, { id }) => {
      setDeleteModal(false);
      if (data.slug === currentOrg) {
        localStorage.removeItem("audino-ai-org");
        setCurrentOrg("");
      }
      queryClient.setQueryData(
        ["organizations"],
        organizations.filter((res) => res._id !== id)
      );
    },
    onError: (err) => toast.error(err.message),
  });

  return (
    <>
      <div className="min-h-full">
        <AppBar />

        <main className="pt-8 pb-8 bg-[#e4f5ed] min-h-screen">
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="sr-only">Page title</h1>
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-1 lg:gap-8 bg-white shadow sm:rounded-lg p-6">
              <section aria-labelledby="section-1-title">
                <div className="flex items-center justify-between">
                  <h2
                    className="truncate text-2xl font-bold text-gray-700"
                    id="section-1-title"
                  >
                    Organizations
                  </h2>
                  <button
                    type="button"
                    className="flex items-center gap-x-2 ml-auto rounded-md bg-[#70cba2] px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-[#70cba2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#70cba2]"
                    onClick={() => navigate("create")}
                  >
                    Create new org
                    <PlusIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                  </button>
                </div>

                <ul className="divide-y divide-gray-100">
                  {isLoading ? (
                    [...Array(5).keys()].map((load) => (
                      <li
                        className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white  cursor-pointer py-8 sm:py-0"
                        onClick={() => navigate("create")}
                        key={`CardLoader-${load}`}
                      >
                        <CardLoader />
                      </li>
                    ))
                  ) : (
                    <ul className="divide-y divide-gray-100">
                      <div className="  flex flex-wrap items-center justify-between gap-x-6 gap-y-2 py-5 sm:flex-nowrap">
                        <div
                          className="rounded-lg flex justify-start py-2 items-center gap-10 w-full cursor-pointer"
                          onClick={handleRemoveOrgClick}
                        >
                          <div className="">
                            <p className="text-sm  flex gap-4 items-center font-semibold leading-6 text-gray-900">
                              Personal Workspace
                              {!currentOrg && (
                                <span className="inline-flex flex-shrink-0 items-center rounded-full h-5 bg-yellow-50 px-1.5 py-0.5 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                                  Active
                                </span>
                              )}
                            </p>
                            {/* <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p>{audinoUserData.username}</p>
                      </div> */}
                          </div>
                        </div>
                      </div>
                      {organizations.map((organization) => (
                        <li
                          key={organization._id}
                          className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap "
                        >
                          <div
                            className="flex justify-start items-center gap-10 w-full cursor-pointer"
                            onClick={() => handleOrgClick(organization.slug)}
                            data-tooltip-id="org-tooltip"
                            data-tooltip-content="Click to activate this organization"
                          >
                            <Tooltip id="org-tooltip" />
                            <div>
                              <p className="text-sm flex gap-4 items-center font-semibold leading-6 text-gray-900">
                                {organization.name} ({organization.slug})
                                {currentOrg === organization.slug && (
                                  <span className="inline-flex flex-shrink-0 items-center rounded-full h-5 bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                    Active
                                  </span>
                                )}
                              </p>
                              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <p>
                                  Created{" "}
                                  {/* by {organization.owner?.username}  */}
                                  on{" "}
                                  {dayjs(organization.created_date).format(
                                    "Do MMMM YYYY"
                                  )}
                                </p>
                              </div>
                              <div className="flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <p>
                                  Last updated{" "}
                                  <time dateTime={organization.updated_date}>
                                    {dayjs(organization.updated_date).fromNow()}
                                  </time>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="flex w-full flex-none items-center justify-between gap-x-7 sm:w-auto">
                            {/* Settings icon */}
                            {currentOrg === organization.slug ? (
                              <div
                                className="flex items-center justify-center py-4 cursor-pointer  text-gray-400 hover:text-gray-700"
                                onClick={() => navigate(`${organization._id}`)}
                              >
                                <Cog6ToothIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </div>
                            ) : null}
                            {/* action buttons */}
                            <div className="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
                              <Menu as="div" className="relative flex-none">
                                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                  <span className="sr-only">Open options</span>
                                  <EllipsisVerticalIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          className={classNames(
                                            active ? "bg-red-50" : "",
                                            "block px-3 py-1 text-sm leading-6 text-red-900 w-full text-left"
                                          )}
                                          onClick={() => {
                                            setdeleteOrgId(organization._id);
                                            setDeleteModal(true);
                                          }}
                                        >
                                          Delete
                                          <span className="sr-only">
                                            , {organization.slug}
                                          </span>
                                        </button>
                                      )}
                                    </Menu.Item>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </ul>
              </section>
            </div>
            <Footer />
          </div>
        </main>
      </div>

      {/* confirmation modal */}
      <AlertModal
        open={deleteModal}
        setOpen={setDeleteModal}
        onSuccess={handleDeleteOrg}
        onCancel={() => setDeleteModal(false)}
        text="Are you sure, you want to delete this organization ?"
        isLoading={delteOrgMutation.isLoading}
      />
    </>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
