import axios from "axios";
import authHeader from "./auth-header";
import { autoLogout } from "../utils/logout";
import globalParams from "./global-params";

const BASE_URL3 = process.env.REACT_APP_BACKEND_URL3;

export const uploadAudioDataApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL3 + "/upload/audio", data, {
      headers: { ...authHeader() },
      params:{...globalParams()},
    });
    return res.data;
  } catch (e) {
    autoLogout(e);
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const uploadTextFileDataApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL3 + "/upload/text", data, {
      headers: { ...authHeader() },
      params:{...globalParams()},
    });
    return res.data;
  } catch (e) {
    autoLogout(e);
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const uploadFile = async function (file, data) {
  if (!file) return null;

  const res = await axios.put(
    `${BASE_URL3}/upload/put-signed-url`,
    data,
    {
      headers: { ...authHeader() },
      params:{...globalParams()},
    }
    );

  const img_upload_res = await axios.put(res.data.data.url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });

  if (img_upload_res.status === 200) {
    return res.data.data.fileName;
  }
  return null;
};

export const viewFile = async function (file_name, type) {
  // Sending a request to generate a signed url
  const res = await axios.post(
    `${BASE_URL3}/upload/get-signed-url`,
    { file_name, type },
    {
      headers: { ...authHeader() },
      params:{...globalParams()},
    }
  );

  return res.data.url;
};
