import React from "react";
import { Outlet } from "react-router-dom";
import { useUserStore } from "../store/user";

export default function AdminRequired() {
  const userDetail = useUserStore((state) => state.userDetail);

  if (userDetail.userType === "a") return <Outlet />;
  else return <div>Admin Required</div>;
}
