import { useModalStore } from "../../store/modal";
import { FlagIcon } from "@heroicons/react/24/outline";

export default function ReportButton({}) {
  const setReportModal = useModalStore((state) => state.setReportModal);

  return (
    <button
      type="button"
      className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-xs text-red-600 shadow-sm border border-red-600 hover:bg-red-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
      onClick={() => setReportModal(true)}
    >
      <FlagIcon className="h-4 w-4" aria-hidden="true" />
      Report
    </button>
  );
}
