import { create } from "zustand";

export const useModalStore = create((set) => ({
  reportModal: false,

  setReportModal: (data) =>
    set((state) => {
      return {
        ...state,
        reportModal: data,
      };
    }),

    transcriptionModal: false,

    setTranscriptionModal: (data) =>
    set((state) => {
      return {
        ...state,
        transcriptionModal: data,
      };
    }),
}));
