import React, { useState } from "react";
import AppBar from "../../components/AppBar/AppBar";
import Footer from "../../components/Footer/Footer";
import ProfileHeader from "./components/ProfileHeader";
import { useUserStore } from "../../store/user";
import SubscriptionInfo from "./components/SubscriptionInfo";
import { getQuotaApi } from "../../services/user.services";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import CardLoader from "../../components/Loader/CardLoader";

function ProfilePage() {
  const userDetail = useUserStore((state) => state.userDetail);
  const [data, setData] = useState([]);
  const [planName, setPlanName] = useState("");
  const limits = useQuery({
    queryKey: ["quota"],
    staleTime: Infinity,
    queryFn: () => getQuotaApi({ user: { user_id: userDetail._id } }),
    onSuccess: (data) => {
      let sampleData = [];
      Object.keys(data.totalLimit).forEach((key) => {
        sampleData.push({
          key: {
            field: key,
            value:
              key === "duration"
                ? `${data.used[key] / 60}/${data.totalLimit[key] / 60} minutes`
                : `${data.used[key]}/${data.totalLimit[key]}`,
          },
        });
      });
      setPlanName(data.Subscription);
      setData(sampleData);
    },
    onError: (err) => toast.error(err.message),
  });
  return (
    <div className="min-h-full">
      <AppBar />
      <main className="bg-[#e4f5ed] min-h-screen pt-8 pb-8">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <ProfileHeader userDetail={userDetail} />
          <h1 className="font-medium py-4 text-2xl">Subscription Info</h1>
          {limits?.isLoading ? (
            [...Array(1).keys()].map((load) => (
              <CardLoader key={`CardLoader-${load}`} />
            ))
          ) : (
            <SubscriptionInfo
              planName={planName}
              data={data}
              organisationData={limits?.data.organisationLimit}
            />
          )}
          <Footer />
        </div>
      </main>
    </div>
  );
}

export default ProfilePage;
