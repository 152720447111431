import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline.esm.js";
import {
  BackwardIcon,
  ForwardIcon,
  MagnifyingGlassMinusIcon,
  PauseCircleIcon,
  PauseIcon,
  PlayCircleIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import TranscriptButton from "../TranscriptModal/TranscriptButton";
import ReportButton from "../ReportModal/ReportButton";

// Create a second timeline
const bottomTimline = TimelinePlugin.create({
  // height: 10,
  timeInterval: 0.1,
  primaryLabelInterval: 1,
  style: {
    fontSize: "10px",
    color: "#6A3274",
  },
});

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: "#36454F",
  progressColor: "#70cba2",
  cursorColor: "#70cba2",
  // barWidth: 5,
  // barRadius: 3,
  responsive: true,
  height: 100,
  normalize: true,
  partialRender: true,
  minPxPerSec: 100,
  plugins: [bottomTimline],
});

export default function Waveform({
  url,
  wavesurfer,
  isTranscript = false,
  isReport = false,
}) {
  const waveformRef = useRef(null);

  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    // Add a loader to the Wavesurfer instance
    wavesurfer.current.on("loading", function () {
      setLoader(true);
    });

    wavesurfer.current.on("ready", function () {
      if (wavesurfer.current) {
        setLoader(false);
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });

    wavesurfer.current.load(url);

    return () => wavesurfer.current.destroy();
  }, [url]);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  const onVolumeChange = (e) => {
    const newVolume = +e.target.value;

    if (newVolume) {
      setVolume(newVolume);
      wavesurfer.current.setVolume(newVolume || 1);
    }
  };

  const handleSkipAudioForward = () => {
    wavesurfer.current.setTime(5);
  };

  return (
    <div>
      {loader ? (
        <div className="flex items-center justify-center h-20">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div
          id="waveform"
          ref={waveformRef}
          className="bg-white rounded-lg mt-10 mb-4"
        ></div>
      )}
      <div className="flex  items-center justify-between py-4">
        <button
          onClick={handlePlayPause}
          className="bg-[#70cba2] h-8 w-8 rounded-full flex items-center justify-center"
        >
          {playing ? (
            <PauseIcon className="h-5 w-5 text-white" />
          ) : (
            <PlayIcon className="h-5 w-5 text-white" />
          )}
        </button>

        <div className="flex gap-2">
          <div className="flex items-center justify-center gap-2">
            <svg class="ml-6 h-6 w-6 flex-none" fill="none">
              <path
                d="M14 5 9 9H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h3l5 4V5Z"
                fill="#64748B"
                stroke="#64748B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M19 12c0-1.5-1-2-1-2v4s1-.5 1-2Z"
                stroke="#64748B"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <input
              type="range"
              min={0}
              max={1}
              step="any"
              value={volume}
              onChange={onVolumeChange}
              className="cursor-pointer:"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2 justify-end">
        {isTranscript && <TranscriptButton />}
        {isReport && <ReportButton />}
      </div>
    </div>
  );
}
