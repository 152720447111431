import React, { useState } from "react";
import {
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  deleteSubscriptionApi,
  UpdateSubscriptionUserApi,
} from "../../../services/subscription.services";
import toast from "react-hot-toast";
import AlertModal from "../../../components/Alert/AlertModal";

function ContentBox({
  title,
  email,
  type,
  isOrgOrUser,
  id,
  subOptions,
  selectedSubscription,
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedSub, setSelectedSub] = useState(
    selectedSubscription
      ? selectedSubscription[0]
        ? selectedSubscription[0].name
        : ""
      : ""
  );

  const handleDeleteSub = () => {
    deleteSubMutation.mutate({
      subscriptionId: id,
      type: isOrgOrUser ? "org" : "user",
    });
    setDeleteModal(false);
  };

  const handleDelete = () => {
    setDeleteModal(true);
  };

  //delete subscription mutation

  const deleteSubMutation = useMutation({
    mutationFn: deleteSubscriptionApi,
    onSuccess: (data, id) => {
      if (isOrgOrUser) {
        const subscriptions = queryClient.getQueryData(["orgSubscriptions"]);
        queryClient.setQueryData(
          ["orgSubscriptions"],
          subscriptions.filter((res) => res._id !== id.subscriptionId)
        );
      } else {
        const subscriptions = queryClient.getQueryData(["userSubscriptions"]);
        queryClient.setQueryData(
          ["userSubscriptions"],
          subscriptions.filter((res) => res._id !== id.subscriptionId)
        );
      }

      toast.success("Subscription deleted successfully");
    },
    onError: (err) => toast.error(err.message),
  });

  const handleSubChange = (event) => {
    const newSub = event.target.value;
    if (newSub == "") {
    } else {
      setSelectedSub(newSub);
      const payload = {
        id: id,
        Sub: newSub,
        type: isOrgOrUser ? "org" : "user",
      };
      updateSubscriptionMutation.mutate(payload);
    }
  };

  const updateSubscriptionMutation = useMutation({
    mutationFn: UpdateSubscriptionUserApi,
    onSuccess: () => {
      toast.success("Subscription Updated");
      // onMembershipUpdate();
      if (isOrgOrUser) {
        const Data = queryClient.getQueryData(["organizationsAll"]);
        let toset;
        subOptions.map((element) => {
          if (element.name === selectedSub) {
            toset = element;
          }
        });
        Data?.map((element) => {
          if (element._id === id) {
            element["subscriptions"][0] = toset;
          }
        });
      } else {
        const Data = queryClient.getQueryData(["users"]);
        let toset;
        subOptions.map((element) => {
          if (element.name === selectedSub) {
            toset = element;
          }
        });
        Data?.map((element) => {
          if (element._id === id) {
            element["subscriptions"][0] = toset;
          }
        });
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
  return (
    <>
      <div className="flex justify-between items-center border-b py-3 first:pt-0">
        <div className="text-sm leading-5 text-gray-500">
          <div className="font-medium text-gray-900">{title}</div>
          {!isOrgOrUser && !type ? (
            <div >{email}</div>
          ) : null}
        </div>
        {!type ? (
          <div className="col-span-1 w-36 sm:col-span-2 md:col-span-2 align-left ">
            <select
              id={id}
              className={`border border-gray-300 focus:border-green-100 block w-full text-green-600 rounded-md focus:ring-green-400`}
              value={selectedSub}
              onChange={handleSubChange}
            >
              <option value="" className="">
                Select an option
              </option>
              {subOptions?.map((option) => {
                return (
                  <option key={option._id} value={option.name} className="">
                    {option.name}
                  </option>
                );
              })}
            </select>
          </div>
        ) : (
          <div className="px-5 flex">
            <button
              className="px-2"
              onClick={() => {
                handleDelete();
              }}
            >
              <TrashIcon className="h-4 w-4  cursor-pointer" />
            </button>
            <button className="px-2">
              <PencilIcon
                className="h-4 w-4  cursor-pointer"
                onClick={() => {
                  navigate(`${id}`, {
                    state: {
                      orgPresent: isOrgOrUser,
                    },
                  });
                }}
              />
            </button>
          </div>
        )}
      </div>
      {/* <hr className="my-4 bg-[#E0E0E0] " /> */}
      <AlertModal
        open={deleteModal}
        setOpen={setDeleteModal}
        onSuccess={handleDeleteSub}
        onCancel={() => setDeleteModal(false)}
        text="Are you sure, you want to delete this subscription ?"
        isLoading={deleteSubMutation.isLoading}
      />
    </>
  );
}

export default ContentBox;
