import axios from "axios";
import authHeader from "./auth-header";
import { autoLogout } from "../utils/logout";
import globalParams from "./global-params";

const BASE_URL3 = process.env.REACT_APP_BACKEND_URL3;

export const fetchAllVideoMsgsApi = async (data) => {
  try {
    const res = await axios.get(BASE_URL3 + "/chat", {
      headers: { ...authHeader() },
      params: {
        video: data.videoID,
        ...globalParams(),
      },
    });

    return res.data;
  } catch (e) {
    autoLogout(e);
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const postCommentApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL3 + "/chat", data, {
      headers: { ...authHeader() },
      params: { ...globalParams() },
    });
    if (!res.data.success) {
      throw res.data;
    }
    return res.data;
  } catch (e) {
    // autoLogout(e);
    throw Error(e.response?.data.msg ?? e.msg ?? "Something went wrong");
  }
};

export const fetchAllAudioMsgsApi = async (data) => {
  try {
    const res = await axios.get(BASE_URL3 + "/msg/audio", {
      headers: { ...authHeader() },
      params: {
        audio_id: data.audioId,
        ...globalParams(),
      },
    });
    return res.data;
  } catch (e) {
    autoLogout(e);
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const postAudioCommentApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL3 + "/msg/audio", data, {
      headers: { ...authHeader() },
      params: { ...globalParams() },
    });
    return res.data;
  } catch (e) {
    autoLogout(e);
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};
