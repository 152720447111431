import axios from "axios";
import authHeader from "./auth-header";
import globalParams from "./global-params";

const BASE_URL3 = process.env.REACT_APP_BACKEND_URL3;

export const getSubscriptionsApi = async ({ type }) => {
  try {
    let typeoforg = "";
    if (type === "org") {
      typeoforg = "org";
    }
    const res = await axios.get(
      BASE_URL3 + "/subscription" + typeoforg + "/all",
      {
        headers: { ...authHeader() },
        params: { ...globalParams() },
      }
    );
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const createSubscriptionApi = async ({ data, type }) => {
  try {
    let typeoforg = "";
    if (type === "org") {
      typeoforg = "org";
    }
    const res = await axios.post(
      BASE_URL3 + "/subscription" + typeoforg + "/",
      data,
      {
        headers: { ...authHeader() },
        params: { ...globalParams },
      }
    );
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getSingleSubscriptionAPI = async ({ id = "", type }) => {
  try {
    let typeoforg = "";
    if (type === "org") {
      typeoforg = "org";
    }
    const res = await axios.get(
      BASE_URL3 + "/subscription" + typeoforg + `/${id}`,
      {
        headers: { ...authHeader() },
      }
    );
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const updateSubscriptionApi = async ({ data, type }) => {
  try {
    let typeoforg = "";
    if (type === "org") {
      typeoforg = "org";
    }
    const res = await axios.put(
      BASE_URL3 + "/subscription" + typeoforg + "/update/",
      data,
      {
        headers: { ...authHeader() },
        params: { ...globalParams },
      }
    );
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const deleteSubscriptionApi = async ({ subscriptionId, type }) => {
  try {
    let typeoforg = "";
    if (type === "org") {
      typeoforg = "org";
    }
    const res = await axios.delete(
      BASE_URL3 + "/subscription" + typeoforg + "/" + subscriptionId,
      {
        headers: { ...authHeader() },
        params: { ...globalParams },
      }
    );
    return res.data.msg;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const UpdateSubscriptionUserApi = async (data) => {
  try {
    let typeoforg = "";
    if (data.type === "org") {
      typeoforg = "org";
    } else {
      typeoforg = "user";
    }
    const res = await axios.patch(
      BASE_URL3 + "/sub-" + typeoforg + "-mapping/change",
      data,
      {
        headers: { ...authHeader() },
      }
    );
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};
