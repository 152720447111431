import axios from "axios";
import authHeader from "./auth-header";
import globalParams from "./global-params";
const BASE_URL = process.env.REACT_APP_BACKEND_URL3;

export const getInvitationApi = async (key = "") => {
  try {
    const response = await axios.get(BASE_URL + `/invitations/${key}`, {
      params: { ...globalParams() },
      headers: { ...authHeader() },
    });
    return response.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const createInvitationApi = async ({ invitationData }) => {
  try {
    const response = await axios.post(
      BASE_URL + `/invitations/`,
      invitationData,
      {
        params: { ...globalParams() },
        headers: { ...authHeader() },
      }
    );
    if (!response.data.success) {
      throw response.data;
    }
    return response.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? e.msg ?? "Something went wrong");
  }
};
