import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import LeftSection from "./components/LeftSection";
import { useQuery } from "@tanstack/react-query";
// import { getUserData } from "../../services/user.services";
import { useUserStore } from "../../store/user";
import { getVideoDetailApi } from "../../services/video.services";
import { toast } from "react-hot-toast";
import AppBar from "../../components/AppBar/AppBar";
import ChatSection from "../../components/ChatSection";
import { fetchAllVideoMsgsApi } from "../../services/msg.services";
import { useVideoStore } from "../../store/video";

export default function CallCenterDetailPage({}) {
  const searchParams = new URLSearchParams(document.location.search);
  const youtubeID = searchParams.get("ytbId", "");
  const videoID = searchParams.get("id");

  const setChatActivity = useVideoStore((state) => state.setChatActivity);

  const getVideoDetailQuery = useQuery({
    queryKey: ["getVideoDetail", videoID],
    enabled: false,
    staleTime: Infinity,
    queryFn: () => getVideoDetailApi(videoID),
    onSuccess: (data) => console.log("success", data),
    onError: (err) => toast.error(err.message),
  });

  const getAllMsgsQuery = useQuery({
    queryKey: ["getAllMsgs", videoID],
    queryFn: () => fetchAllVideoMsgsApi({ videoID }),
    enabled: false,
    staleTime: Infinity,
    onError: (err) => {
      setChatActivity([]);
      // toast.error(err.message);
    },
    onSuccess: (data) => setChatActivity(data.data),
  });

  useEffect(() => {
    if (videoID) {
      getVideoDetailQuery.refetch();
      getAllMsgsQuery.refetch({ videoID });
    }
  }, [videoID]);

  return (
    <>
      <div className="min-h-full">
        <AppBar />

        <main className="pt-8 pb-8 bg-[#e4f5ed] min-h-screen">
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="sr-only">Page title</h1>
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8 ">
              {/* Left column */}
              <div className="grid grid-cols-1 gap-4 lg:col-span-2 h-full">
                <section aria-labelledby="section-1-title">
                  <h2 className="sr-only" id="section-1-title">
                    Video section
                  </h2>
                  <LeftSection
                    youtubeID={youtubeID}
                    getVideoDetailQuery={getVideoDetailQuery}
                    videoID={videoID}
                  />
                </section>
              </div>

              {/* Right column */}
              <div className="grid grid-cols-1 gap-4  sticky top-8">
                <section aria-labelledby="section-2-title">
                  <h2 className="sr-only" id="section-2-title">
                    Chat section
                  </h2>
                  <div className="overflow-hidden rounded-lg bg-white shadow">
                    <div className="p-6">
                      <ChatSection
                        sectionType="video"
                        value={{
                          id: videoID,
                          // ytb_vid_id:
                          //   getVideoDetailQuery.data?.video_data?.ytb_vid_id,
                        }}
                        getAllMsgsQuery={getAllMsgsQuery}
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </main>
      </div>
    </>
  );
}
