export function handleSignout () {
    localStorage.removeItem("audino-ai-token");
    localStorage.removeItem("audino-ai-user");
    localStorage.removeItem("audino-ai-org")
    // navigate("/");
    window.location.reload();
  };

export function autoLogout(e){
    console.log('====================================');
    console.log("response status", e.response.status);
    console.log('====================================');
    if(e.response.status === 401){
        handleSignout();
    }
}