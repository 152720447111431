import { Dialog, Transition } from "@headlessui/react";
import { ArrowsRightLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import TextSegment from "./TextSegment";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import {
  getAsrDetailApi,
  updateAsrDetailApi,
} from "../../services/video.services";
import { useModalStore } from "../../store/modal";

export default function TranscriptionModal({ videoID, isButtonDisable=false }) {
  const queryClient = useQueryClient();
  const [updatedSegments, setUpdatedSegments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [replaceTerm, setReplaceTerm] = useState("");
  const [foundWordCount, setFoundWordCount] = useState(-1);

  const setTranscriptionModal = useModalStore(
    (state) => state.setTranscriptionModal
  );
  const transcriptionModal = useModalStore((state) => state.transcriptionModal);

  const getAsrDetailQuery = useQuery({
    queryKey: ["getAsrDetail", videoID],
    enabled: false,
    staleTime: Infinity,
    queryFn: () => getAsrDetailApi(videoID),
    onSuccess: (data) =>
      setUpdatedSegments(
        data.map((e) => ({
          id: e.id,
          text: e.text,
          start: parseInt(e.start).toFixed(0),
          end: parseInt(e.end).toFixed(0),
        }))
      ),
    onError: (err) => toast.error(err.message),
  });

  useEffect(() => {
    if (transcriptionModal)
      getAsrDetailQuery.refetch({ queryKey: ["getAsrDetail", videoID] });
  }, [transcriptionModal, videoID]);

  const handleSegmentUpdate = (segmentId, newText) => {
    const updated = [...updatedSegments];
    updated[segmentId].text = newText;
    setUpdatedSegments(updated);
  };

  const asrDetailUpdateMutation = useMutation({
    mutationFn: updateAsrDetailApi,
    onSuccess: (data) => {
      setTranscriptionModal(false);
      toast.success(data.msg);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const asrDetailUpdateRerunMutation = useMutation({
    mutationFn: updateAsrDetailApi,
    onSuccess: (data) => {
      setTranscriptionModal(false);
      toast.success(data.msg);
      const currentVideo = queryClient.getQueryData([
        "getVideoDetail",
        videoID,
      ]);

      queryClient.setQueryData(["getVideoDetail", videoID], {
        ...currentVideo,
        tasks: data.data.tasks,
      });
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleSave = () => {
    const changedSegments = updatedSegments.filter(
      (segment, index) => segment.text !== getAsrDetailQuery.data[index].text
    );
    asrDetailUpdateMutation.mutate({
      videoID,
      data: { asr: changedSegments, is_rerun: false },
    });
  };

  const handleSaveRerun = () => {
    const changedSegments = updatedSegments.filter(
      (segment, index) => segment.text !== getAsrDetailQuery.data[index].text
    );
    asrDetailUpdateRerunMutation.mutate({
      videoID,
      data: { asr: changedSegments, is_rerun: true },
    });
  };

  const updateFoundWordCount = (segments, searchWord) => {
    const count = segments.reduce((total, segment) => {
      const matches = segment.text.match(new RegExp(searchWord, "g")) || [];
      return total + matches.length;
    }, 0);
    return count;
  };

  const handleFindReplace = () => {
    let newUpdatedSegments = [...updatedSegments];
    newUpdatedSegments = newUpdatedSegments.map((segment) => ({
      ...segment,
      text: segment.text.replace(new RegExp(searchTerm, "g"), replaceTerm),
    }));

    setFoundWordCount(0);
    setUpdatedSegments(newUpdatedSegments);
  };

  useEffect(() => {
    if (searchTerm) {
      const count = updateFoundWordCount(updatedSegments, searchTerm);
      setFoundWordCount(count);
    } else {
      setFoundWordCount(-1);
    }
  }, [searchTerm]);

  return (
    <Transition.Root show={transcriptionModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={setTranscriptionModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll bg-white">
                      <div className="p-4 sm:p-6 border-b">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Transcripts
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 "
                              onClick={() => setTranscriptionModal(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>

                        <div className="mt-4">
                          <p class="text-sm text-gray-500 text-right">
                            {foundWordCount >= 0
                              ? `Found ${foundWordCount} matches`
                              : " "}
                          </p>

                          <div className="flex -space-x-px">
                            <div className="w-1/2 min-w-0 flex-1">
                              <label htmlFor="find-input" className="sr-only">
                                Find
                              </label>
                              <input
                                type="text"
                                name="find-input"
                                id="find-input"
                                className="relative block w-full rounded-none rounded-l-md border-0 bg-transparent p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 sm:text-sm sm:leading-6 focus:outline"
                                placeholder="Find"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                            </div>
                            <div className="min-w-0 flex-1">
                              <label
                                htmlFor="replace-input"
                                className="sr-only"
                              >
                                Replace
                              </label>
                              <input
                                type="text"
                                name="replace-input"
                                id="replace-input"
                                className="relative block w-full rounded-none border-0 bg-transparent p-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 sm:text-sm sm:leading-6 focus:outline"
                                placeholder="Replace"
                                value={replaceTerm}
                                onChange={(e) => setReplaceTerm(e.target.value)}
                              />
                            </div>
                            <button
                              type="button"
                              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-normal text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={handleFindReplace}
                            >
                              <ArrowsRightLeftIcon
                                className="-ml-0.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              Replace
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex-1 px-4 sm:px-6 mb-4">
                        {getAsrDetailQuery.isLoading ? (
                          <>
                            {[...Array(5).keys()].map((e) => (
                              <div className="flex-auto rounded-md animate-pulse mt-2">
                                <div className="w-full h-12 bg-[#70cba2] rounded-md"></div>
                              </div>
                            ))}
                          </>
                        ) : (
                          updatedSegments.map((segment) => (
                            <TextSegment
                              key={segment.id}
                              segment={segment}
                              onUpdate={handleSegmentUpdate}
                            />
                          ))
                        )}
                      </div>
                    </div>
                  {!isButtonDisable &&  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                        onClick={() => handleSave()}
                        disabled={asrDetailUpdateMutation.isLoading}
                      >
                        {asrDetailUpdateMutation.isLoading
                          ? "Saving..."
                          : "Save"}
                      </button>

                      <button
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md bg-[#70cba2] px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        onClick={() => handleSaveRerun()}
                        disabled={asrDetailUpdateRerunMutation.isLoading}
                      >
                        {asrDetailUpdateRerunMutation.isLoading
                          ? "Loading..."
                          : "Save & Rerun"}
                      </button>
                    </div>}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
