import { Navigate, Route, Routes } from "react-router-dom";
import { useUserStore } from "../store/user";
import VideoPage from "../pages/VideoPage/VideoPage";
import VideoDetailPage from "../pages/VideoDetailPage/VideoDetailPage";
import FeaturesPage from "../pages/FeaturesPage/FeaturesPage";
import AudioPage from "../pages/AudioPage/AudioPage";
import AudioDetailPage from "../pages/AudioDetailPage/AudioDetailPage";

import { useQuery } from "@tanstack/react-query";
import { getUserData } from "../services/user.services";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import TextPage from "../pages/TextPage/TextPage";
import TextDetailPage from "../pages/TextDetailPage/TextDetailPage";
import AudioSummaryPage from "../pages/AudioSummaryPage/AudioSummaryPage";
import AudioSummaryDetailPage from "../pages/AudioSummaryDetailPage/AudioSummaryDetailPage";
import CallCenterPage from "../pages/CallCenterPage/CallCenterPage";
import MeetingsPage from "../pages/MeetingsPage/MeetingsPage";
import CallCenterDetailPage from "../pages/CallCenterDetailPage/CallCenterDetailPage";
import MeetingDetailPage from "../pages/MeetingDetailPage/MeetingDetailPage";
import OrganizationPage from "../pages/OrganizationPage/OrganizationPage";
import AddOrganizationPage from "../pages/AddOrganizationPage/AddOrganizationPage";
import SubscriptionPage from "../pages/SubscriptionPage/SubscriptionPage";
import AdminRequired from "../components/AdminRequired";
import AddSubscriptionPage from "../pages/AddSubscriptionPage/AddSubscriptionPage";
import ProfilePage from "../pages/ProfilePage/ProfilePage";
import UserPage from "../pages/UserPage/UserPage";

function AudinoRoutes() {
  const token = useUserStore((state) => state.token);
  const setUserDetail = useUserStore((state) => state.setUserDetail);
  const userDetail = useUserStore((state) => state.userDetail);

  const getUserDetailQuery = useQuery({
    queryKey: ["getUserData"],
    enabled: false,
    staleTime: Infinity,
    queryFn: getUserData,
    onError: (err) => toast.error(err.message),
  });

  useEffect(() => {
    if (userDetail.user_id == "") {
      getUserDetailQuery.refetch();
    }
  }, []);

  useEffect(() => {
    if (getUserDetailQuery?.data) {
      console.log("getUserDetailQuery.data", getUserDetailQuery.data);
      setUserDetail(getUserDetailQuery.data);
    }
  }, [getUserDetailQuery?.data]);

  return token ? (
    <>
      <Routes>
        <Route path="/features" element={<FeaturesPage />} />
        <Route path="/content-ai" element={<VideoPage />} />
        <Route path="/detail" element={<VideoDetailPage />} />

        <Route path="/call-recording-ai" element={<CallCenterPage />} />
        <Route
          path="/call-recording-detail"
          element={<CallCenterDetailPage />}
        />

        <Route path="/meetings-ai" element={<MeetingsPage />} />
        <Route path="/meeting-detail" element={<MeetingDetailPage />} />

        <Route path="/organizations" element={<OrganizationPage />} />
        <Route path="/organizations/create" element={<AddOrganizationPage />} />
        <Route path="/organizations/:id" element={<AddOrganizationPage />} />

        <Route
          path="/hate-speech-detail"
          element={<AudioSummaryDetailPage />}
        />
        <Route path="/hate-speech-ai" element={<AudioSummaryPage />} />

        <Route element={<AdminRequired />}>
          <Route path="/subscriptions" element={<SubscriptionPage />} />
          <Route
            path="/subscriptions/create"
            element={<AddSubscriptionPage />}
          />
          <Route path="/subscriptions/:id" element={<AddSubscriptionPage />} />
          <Route path="/users" element={<UserPage />} />
        </Route>
        <Route path="/profiles" element={<ProfilePage />} />

        {/* <Route path="/audios-summary" element={<AudioSummaryPage />} />
        <Route path="/audio-gpt" element={<AudioSummaryDetailPage />} />

        <Route path="/audios" element={<AudioPage />} />
        <Route path="/audio" element={<AudioDetailPage />} />

        <Route path="/texts" element={<TextPage />} />
        <Route path="/text" element={<TextDetailPage />} /> */}
      </Routes>
    </>
  ) : (
    <Navigate to="/" />
  );
}

export default AudinoRoutes;
