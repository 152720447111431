import React, { useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useMutation } from "@tanstack/react-query";
import { uploadVideoUrlApi } from "../../services/video.services";
import axios from "axios";
import { googleAuth } from "../../services/user.services";
import { toast } from "react-hot-toast";
import { useUserStore } from "../../store/user";

export default function HomePage({}) {
  const navigate = useNavigate();
  // const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //TODO:setuserDetail not being called
  const token = useUserStore((state) => state.token);
  const setToken = useUserStore((state) => state.setToken);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  const handleGetSummaryButton = () => {
    navigate("/features");
    // uploadVideoUrlMutation.mutate({ video_url: inputValue });
  };

  // const youtubeParser = (url) => {
  //   var regExp =
  //     /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  //   var match = url.match(regExp);
  //   return match && match[7].length === 11 ? match[7] : false;
  // };

  // // post ytb url and get document id
  // const uploadVideoUrlMutation = useMutation({
  //   mutationFn: uploadVideoUrlApi,
  //   onSuccess: (data) => {
  //     toast.success(data.msg)
  //     // const { document } = data;
  //     const ytbId = youtubeParser(inputValue);
  //     navigate(`/detail?videoId=${data.data.video_id}&ytbId=${ytbId}`);
  //   },
  //   onError: (err) =>{
  //     toast.error(err.message)
  //   }
  // });

  useEffect(() => {
    const googleScript = document.getElementById("googleScript");

    if (window.google) {
      // All is good! Carry on
      googleButtonInitialize();
    }

    googleScript.addEventListener("load", () => {
      // Patiently waiting to do the thing
      googleButtonInitialize();
    });

    return () => {
      googleScript.removeEventListener("load", null);
    };
  }, []);

  const googleButtonInitialize = () => {
    window.google?.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
      context: "signup",
    });

    window.google?.accounts.id.renderButton(
      document.getElementById("signInGoogle"),
      {
        theme: "outline",
        size: "large",
        text: "signin_with",
        shape: "circle",
      }
    );
  };

  const handleCallbackResponse = (res) => {
    const encoded_credential = res.credential;
    setIsLoading(true);
    googleAuth(encoded_credential)
      .then((res) => {
        console.log("====================================");
        console.log("res", res);
        console.log("====================================");
        setToken(res.data.token);
        toast.success(res.msg);
        navigate("/features");
        // window.location.reload();
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="bg-white overflow-hidden">
      {/* Hero section */}
      <div className="relative bg-gray-900 h-screen w-screen">
        {/* Decorative image and overlay */}
        <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              fullScreen: false,
              background: {
                image: " linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)",
              },
              particles: {
                number: {
                  value: 10,
                  density: { enable: true, value_area: 600 },
                },
                color: { value: "#ffffff" },
                shape: {
                  type: "square",
                  stroke: { width: 0, color: "#000000" },
                  polygon: { nb_sides: 5 },
                },
                opacity: {
                  value: 0.25,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                  },
                },
                size: {
                  value: 29,
                  random: true,
                  anim: { enable: false, speed: 2, size_min: 0.1, sync: false },
                },
                line_linked: {
                  enable: false,
                  distance: 300,
                  color: "#ffffff",
                  opacity: 0,
                  width: 0,
                },
                move: {
                  enable: true,
                  speed: 0.5,
                  direction: "top",
                  straight: true,
                  out_mode: "out",
                  bounce: false,
                  attract: { enable: false, rotateX: 600, rotateY: 1200 },
                },
              },
              interactivity: {
                detect_on: "canvas",
                events: {
                  onhover: { enable: false, mode: "repulse" },
                  onclick: { enable: false, mode: "push" },
                  resize: true,
                },
                modes: {
                  grab: { distance: 800, line_linked: { opacity: 1 } },
                  bubble: {
                    distance: 790,
                    size: 79,
                    duration: 2,
                    opacity: 0.8,
                    speed: 3,
                  },
                  repulse: { distance: 400, duration: 0.4 },
                  push: { particles_nb: 4 },
                  remove: { particles_nb: 2 },
                },
              },
              retina_detect: true,
            }}
          />
        </div>
        <div aria-hidden="true" className="absolute inset-0" />
        <div className="relative mx-auto flex max-w-3xl flex-col items-center justify-center px-6 py-32 text-center sm:py-64 lg:px-0 h-full">
          <img
            className="h-16 w-auto"
            src={require("../../assets/logos/logo.png")}
            alt="Audino"
          />
          <h1 className="text-4xl font-bold tracking-tight text-white lg:text-6xl">
            The Playground of Audio and Video Magic
          </h1>
          <p className="mt-4 text-xl text-white">
            Step into our playground of AI wizardry, where audio becomes text,
            and video turns into concise gems. Elevate your content experience
            today.
          </p>
          {/* <div className="mt-6 px-4 w-full">
            <label htmlFor="url" className="sr-only">
              Url
            </label>
            <input
              type="text"
              name="url"
              id="url"
              className="block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 !outline-none"
              placeholder="URL of a YouTube video"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div> */}
          <div className="flex gap-2 items-center mt-8">
            <button
              type="button"
              className="inline-block rounded-md border border-transparent  border-white px-8 py-2 text-base font-medium text-white hover:text-gray-900 hover:bg-gray-100 disabled:cursor-not-allowed"
              onClick={() => (window.location.href = "https://audino.in/")}
              // disabled={inputValue === "" || uploadVideoUrlMutation.isLoading}
            >
              {" "}
              Back to website
              {/* {uploadVideoUrlMutation.isLoading
                  ? "Loading..."
                  : "Get summary now!"} */}
            </button>
            {
              token ? (
                <button
                  type="button"
                  className="inline-block rounded-md border border-transparent bg-white px-8 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 disabled:cursor-not-allowed"
                  onClick={handleGetSummaryButton}
                  // disabled={inputValue === "" || uploadVideoUrlMutation.isLoading}
                >
                  {" "}
                  Let's get started!
                  {/* {uploadVideoUrlMutation.isLoading
                  ? "Loading..."
                  : "Get summary now!"} */}
                </button>
              ) : isLoading ? (
                <div class="flex justify-center items-center pl-4">
                  <div class="relative w-10 h-10 animate-spin rounded-full bg-gradient-to-r from-purple-400 via-blue-500 to-red-400 ">
                    <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 rounded-full border-2 border-white bg-white"></div>
                  </div>
                </div>
              ) : (
                <div
                  id="signInGoogle"
                  className="rounded-full flex gap-3 items-center justify-center py-2 font-['nunito'] text-[#333333]"
                ></div>
              )
              // <button
              //   type="button"
              //   className="mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 disabled:cursor-not-allowed"
              // onClick={profile?.name ? onLogout : onLogin}
              // disabled={inputValue === "" || uploadVideoUrlMutation.isLoading}
              // >
              //   Login
              // </button>
            }
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer aria-labelledby="footer-heading" className="bg-gray-900">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="border-t border-gray-800 py-10">
            <p className="text-sm text-gray-400">
              Copyright &copy; 2023 SigSAIL Pvt.Ltd.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
