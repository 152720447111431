import React, { useEffect, useState } from "react";
import ToggleButton from "./ToggleButton";
import ContentBox from "./ContentBox";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination";
import SearchBox from "./SearchBox";
import UsersList from "./UsersList";
import { useDataQuery } from "../hooks/useDataQuery";
import { getSubscriptionsApi } from "../../../services/subscription.services";
import { getAllUserDataApi } from "../../../services/user.services";
import OrganizationsList from "./OrganizationsList";
import { getAllOrganizationsApi } from "../../../services/organization.services";
import UserSubscriptionList from "./UserSubscriptionList";
import OrgSubscriptionList from "./OrgSubscriptionList";

const limit = 10;

function LeftSection({}) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [isOrgOrUser, setIsOrgOrUser] = useState(false);
  const [subscriptionSelectedIn1st, setSubscriptionSelectedIn1st] =
    useState(false);
  const [searchText, setSearchText] = useState("");

  const usersQuery = useDataQuery(
    ["users", page, searchText],
    () => getAllUserDataApi({ page, limit, searchText }),
    isOrgOrUser === false
  );
  const userSubscriptionsQuery = useDataQuery(
    ["userSubscriptions"],
    () => getSubscriptionsApi({ type: "user" }),
    isOrgOrUser === false
  );

  const orgSubscriptionsQuery = useDataQuery(
    ["orgSubscriptions"],
    () => getSubscriptionsApi({ type: "org" }),
    isOrgOrUser === true
  );

  const organizationsQuery = useDataQuery(
    ["organizationsAll", page, searchText],
    () => getAllOrganizationsApi({ page, limit, searchText }),
    isOrgOrUser === true
  );

  useEffect(() => {
    setPage(0);
  }, [isOrgOrUser]);

  return (
    <>
      <div className="overflow-hidden rounded-lg bg-white shadow  p-6">
        <div className="flex justify-between">
          <h2 className="truncate text-2xl font-semibold text-gray-600">
            {isOrgOrUser ? "Organisation" : "User"}
            {subscriptionSelectedIn1st ? " Subscriptions" : "s"}
          </h2>
          <div>
            <ToggleButton
              firstField={"Assign List"}
              secondField={"Subscription"}
              handleClick={setSubscriptionSelectedIn1st}
            />
          </div>
        </div>
        <div className="border-x border-t rounded-t-lg px-8 py-4 my-5 mb-0 flex justify-between">
          <SearchBox setSearchText={setSearchText} />
          <ToggleButton
            firstField={"User"}
            secondField={"Organisation"}
            handleClick={setIsOrgOrUser}
          />
        </div>
        <div className="border rounded-b-lg border-gray-200 py-4 px-5">
          {/* show data based on the button selected */}
          {subscriptionSelectedIn1st ? (
            <div>
              <div className="flex justify-between">
                <button
                  onClick={() =>
                    navigate("create", {
                      state: {
                        orgPresent: isOrgOrUser,
                      },
                    })
                  }
                >
                  <div className="font-semibold text-[#70cba2]">
                    Add a Subscription
                  </div>
                </button>
                <button
                  onClick={() =>
                    navigate("create", {
                      state: {
                        orgPresent: isOrgOrUser,
                      },
                    })
                  }
                >
                  <PlusCircleIcon className="w-6 h-6 text-[#70cba2]" />
                </button>
              </div>
              <hr className="my-4 bg-[#E0E0E0] " />
            </div>
          ) : null}
          {!subscriptionSelectedIn1st && !isOrgOrUser ? (
            <UsersList
              userSubscriptionsQuery={userSubscriptionsQuery}
              usersQuery={usersQuery}
              page={page}
              setPage={setPage}
            />
          ) : null}
          {!subscriptionSelectedIn1st && isOrgOrUser ? (
            <OrganizationsList
              orgSubscriptionsQuery={orgSubscriptionsQuery}
              organizationsQuery={organizationsQuery}
              page={page}
              setPage={setPage}
            />
          ) : null}
          {subscriptionSelectedIn1st && !isOrgOrUser ? (
            <UserSubscriptionList
              userSubscriptionsQuery={userSubscriptionsQuery}
            />
          ) : null}
          {subscriptionSelectedIn1st && isOrgOrUser ? (
            <OrgSubscriptionList
              orgSubscriptionsQuery={orgSubscriptionsQuery}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default LeftSection;
