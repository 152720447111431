import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { EnvelopeIcon } from "@heroicons/react/20/solid";

import { useModalStore } from "../../store/modal";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { postReportRequestApi } from "../../services/report.services";

const defaultValue = {
  title: "",
  message: "",
};

export default function ReportModal({
  feature_id = "",
  feature = "",
  options = [],
}) {
  const setReportModal = useModalStore((state) => state.setReportModal);
  const reportModal = useModalStore((state) => state.reportModal);

  const [formData, setFormData] = useState({ ...defaultValue });

  // Function to handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const addReportRequestMutation = useMutation({
    mutationFn: postReportRequestApi,
    onSuccess: (data) => {
      toast.success(data.msg);
      setFormData(defaultValue);
      setReportModal(false);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.title || !formData.message) {
      toast.error("Please fill all the fields");
      return;
    }
    addReportRequestMutation.mutate({ ...formData, feature, feature_id });
  };

  return (
    <Transition.Root show={reportModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setReportModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-[#70cba2] px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            Report an issue
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-[#70cba2] text-[#70cba2]-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setReportModal(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-white">
                            Let us know if you have any feedback, bug reports,
                            or feature requests.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="px-4 sm:px-6">
                          <div className="space-y-6 pb-5 pt-6">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                When you noticed this issue, what were you
                                trying to do?
                              </label>
                              <div className="mt-2">
                                <select
                                  id="project-name"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-[#70cba2] sm:text-sm sm:leading-6 outline-none"
                                  name="title"
                                  value={formData.title}
                                  onChange={handleInputChange}
                                >
                                  <option selected value={""}>
                                    Select one...
                                  </option>
                                  {options.map((option, key) => (
                                    <option key={`option-value-${key}`} value={option}>{option}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="message"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Describe your issue
                              </label>
                              <div className="mt-2">
                                <textarea
                                  id="message"
                                  name="message"
                                  rows={4}
                                  className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#70cba2] sm:text-sm sm:leading-6 outline-none"
                                  value={formData.message}
                                  onChange={handleInputChange}
                                  placeholder="Describe your issue"
                                />
                              </div>
                              {/* <div className="mt-4 flex text-sm">
                                <a
                                  href="#"
                                  className="group inline-flex items-center text-gray-500 hover:text-gray-900"
                                >
                                  <EnvelopeIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                  <span className="ml-2">
                                    Mail us directly for help.
                                  </span>
                                </a>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => setReportModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md bg-[#70cba2] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#70cba2]-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#70cba2]"
                        onClick={handleSubmit}
                        disabled={addReportRequestMutation.isLoading}
                      >
                        {addReportRequestMutation.isLoading
                          ? "Sending..."
                          : "Send"}
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
