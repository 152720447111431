import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { PauseIcon, PlayIcon, StopIcon } from "@heroicons/react/20/solid";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import secondsToHMS from "../../utils/secondsToHMS";
import ReportButton from "../ReportModal/ReportButton";
import TranscriptButton from "../TranscriptModal/TranscriptButton";

export default function CustomYouTubePlayer({
  youtubeID,
  videoRef,
  playing,
  setPlaying,
  isReport = false,
  isTranscript = false,
}) {
  const [played, setPlayed] = useState(0);
  const [volume, setVolume] = useState(0.8);
  const [videoDuration, setVideoDuration] = useState(0);

  const seekToTime = (timeInSecond) => {
    window.scrollTo(0, 0);
    const currentPlayedTime = played * videoDuration;
    videoRef.current.seekTo(currentPlayedTime + timeInSecond);
    // if (currentPlayedTime > timeInSecond) {
    //   videoRef.current.seekTo(currentPlayedTime - timeInSecond);
    // } else {
    //   videoRef.current.seekTo(currentPlayedTime + timeInSecond);
    // }
    setPlaying(true);
  };

  return (
    <div className="video-container">
      <ReactPlayer
        ref={videoRef}
        url={`https://www.youtube.com/watch?v=${youtubeID}`}
        controls={false}
        width="100%"
        height="calc(100vh - 24rem)"
        playing={playing}
        volume={volume}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        onProgress={(state) => setPlayed(state.played)}
        onDuration={(duration) => setVideoDuration(duration)}
      />

      <div class="flex my-2">
        <div class="flex items-center space-x-4 pr-6 py-2 ">
          <button onClick={() => seekToTime(-15)}>
            <svg class="h-6 w-6 flex-none" fill="none">
              <path
                d="M6.22 11.03a.75.75 0 1 0 1.06-1.06l-1.06 1.06ZM3 6.75l-.53-.53a.75.75 0 0 0 0 1.06L3 6.75Zm4.28-3.22a.75.75 0 0 0-1.06-1.06l1.06 1.06ZM13.5 18a.75.75 0 0 0 0 1.5V18ZM7.28 9.97 3.53 6.22 2.47 7.28l3.75 3.75 1.06-1.06ZM3.53 7.28l3.75-3.75-1.06-1.06-3.75 3.75 1.06 1.06Zm16.72 5.47c0 2.9-2.35 5.25-5.25 5.25v1.5a6.75 6.75 0 0 0 6.75-6.75h-1.5ZM15 7.5c2.9 0 5.25 2.35 5.25 5.25h1.5A6.75 6.75 0 0 0 15 6v1.5ZM15 6H3v1.5h12V6Zm0 12h-1.5v1.5H15V18Z"
                fill="#64748B"
              ></path>
              <path
                d="M3 15.75h.75V21"
                stroke="#64748B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M9 16.5A.75.75 0 0 0 9 15v1.5Zm-2.25-.75V15a.75.75 0 0 0-.75.75h.75Zm0 2.25H6c0 .414.336.75.75.75V18Zm0 2.25a.75.75 0 0 0 0 1.5v-1.5ZM9 15H6.75v1.5H9V15Zm-3 .75V18h1.5v-2.25H6Zm.75 3h1.5v-1.5h-1.5v1.5Zm1.5 1.5h-1.5v1.5h1.5v-1.5ZM9 19.5a.75.75 0 0 1-.75.75v1.5a2.25 2.25 0 0 0 2.25-2.25H9Zm-.75-.75a.75.75 0 0 1 .75.75h1.5a2.25 2.25 0 0 0-2.25-2.25v1.5Z"
                fill="#64748B"
              ></path>
            </svg>
          </button>
          <button
            onClick={() => setPlaying(!playing)}
            className="bg-[#70cba2] h-8 w-8 rounded-full flex items-center justify-center"
          >
            {playing ? (
              <PauseIcon className="h-5 w-5 text-white" />
            ) : (
              <PlayIcon className="h-5 w-5 text-white" />
            )}
          </button>
          <button onClick={() => seekToTime(15)}>
            <svg class="h-6 w-6 flex-none" fill="none">
              <path
                d="M16.72 9.97a.75.75 0 1 0 1.06 1.06l-1.06-1.06ZM21 6.75l.53.53a.75.75 0 0 0 0-1.06l-.53.53Zm-3.22-4.28a.75.75 0 1 0-1.06 1.06l1.06-1.06ZM10.5 19.5a.75.75 0 0 0 0-1.5v1.5Zm3.75-4.5a.75.75 0 0 0 0 1.5V15Zm.75.75h.75A.75.75 0 0 0 15 15v.75ZM14.25 21a.75.75 0 0 0 1.5 0h-1.5Zm6-4.5a.75.75 0 0 0 0-1.5v1.5ZM18 15.75V15a.75.75 0 0 0-.75.75H18ZM18 18h-.75c0 .414.336.75.75.75V18Zm0 2.25a.75.75 0 0 0 0 1.5v-1.5Zm-.22-9.22 3.75-3.75-1.06-1.06-3.75 3.75 1.06 1.06Zm3.75-4.81-3.75-3.75-1.06 1.06 3.75 3.75 1.06-1.06ZM2.25 12.75A6.75 6.75 0 0 0 9 19.5V18a5.25 5.25 0 0 1-5.25-5.25h-1.5ZM9 6a6.75 6.75 0 0 0-6.75 6.75h1.5C3.75 9.85 6.1 7.5 9 7.5V6Zm0 1.5h12V6H9v1.5Zm0 12h1.5V18H9v1.5Zm5.25-3H15V15h-.75v1.5Zm0-.75V21h1.5v-5.25h-1.5Zm6-.75H18v1.5h2.25V15Zm-3 .75V18h1.5v-2.25h-1.5Zm.75 3h1.5v-1.5H18v1.5Zm1.5 1.5H18v1.5h1.5v-1.5Zm.75-.75a.75.75 0 0 1-.75.75v1.5a2.25 2.25 0 0 0 2.25-2.25h-1.5Zm-.75-.75a.75.75 0 0 1 .75.75h1.5a2.25 2.25 0 0 0-2.25-2.25v1.5Z"
                fill="#64748B"
              ></path>
            </svg>
          </button>
        </div>
        <div class="flex flex-auto items-center border-l border-slate-200/60 pl-6 text-[0.8125rem] leading-5 text-slate-700">
          <div>{secondsToHMS(played * videoDuration)}</div>
          <div className="relative w-full ml-4 mb-2">
            <input
              type="range"
              min={0}
              max={0.999999}
              step="any"
              value={played}
              onChange={(e) => {
                const newValue = parseFloat(e.target.value);
                videoRef.current.seekTo(newValue);
                setPlayed(newValue);
              }}
              className="absolute w-full h-2  cursor-pointer focus:outline-none"
            />
            {/* <div class="-my-[0.3125rem] ml-0.5 h-[1.125rem] w-1 rounded-full bg-indigo-600 absolute"></div> */}
            {/* <div
            className="absolute left-0 top-0 w-full h-2 bg-indigo-500 rounded-l-full -z-1"
            style={{ width: `${played * 100}%` }}
          ></div> */}
          </div>

          <div class="ml-4">{secondsToHMS(videoDuration)}</div>

          <div className="flex gap-2">
            <div className="flex items-center justify-center gap-2">
              <svg class="ml-6 h-6 w-6 flex-none" fill="none">
                <path
                  d="M14 5 9 9H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h3l5 4V5Z"
                  fill="#64748B"
                  stroke="#64748B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M19 12c0-1.5-1-2-1-2v4s1-.5 1-2Z"
                  stroke="#64748B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <input
                type="range"
                min={0}
                max={1}
                step="any"
                value={volume}
                onChange={(e) => setVolume(parseFloat(e.target.value))}
                className="cursor-pointer:"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`flex items-center ${
          isReport ? "justify-between" : "justify-end"
        }`}
      >
        <div className="flex items-center gap-2">
          <p className="text-slate-700 leading-5 text-[0.8125rem]">Share: </p>
          <FacebookShareButton
            url={"https://ai.audino.in/"}
            quote={`
          🎙️ Introducing Audino - Your Audio Magic Hub! 🚀
          
          Experience the future of language AI with Audino:
          
          Real-time Speech-to-Text in 100 languages 🌐
          Lifelike Text-to-Speech in Arabic, Hindi, and English 🔊
          One platform, endless possibilities! Join Audino today. 🌟
          
          `}
            hashtag="#audino #ai #audino-ai #youtube #summary #chapters"
          >
            <FacebookIcon size={25} round />
          </FacebookShareButton>
          <TwitterShareButton
            url={"https://ai.audino.in/"}
            quote={`
          🎙️ Introducing Audino - Your Audio Magic Hub! 🚀
          
          Experience the future of language AI with Audino:
          
          Real-time Speech-to-Text in 100 languages 🌐
          Lifelike Text-to-Speech in Arabic, Hindi, and English 🔊
          One platform, endless possibilities! Join Audino today. 🌟
          
          `}
            hashtag="#audino #ai #audino-ai #youtube #summary #chapters #LanguageAI #AudinoAI #LanguageProcessing #SpeechRecognition #TextToSpeech #AIInnovation   #LanguageServices #TechInnovation #NLP #VoiceTechnology #MultilingualAI #SpeechTech  #DigitalInnovation  #IntelligentAudio  "
          >
            <XIcon size={25} round />
          </TwitterShareButton>
          <LinkedinShareButton
            url={"https://ai.audino.in/"}
            quote={`
          🎙️ Introducing Audino - Your Audio Magic Hub! 🚀
          
          Experience the future of language AI with Audino:
          
          Real-time Speech-to-Text in 100 languages 🌐
          Lifelike Text-to-Speech in Arabic, Hindi, and English 🔊
          One platform, endless possibilities! Join Audino today. 🌟
          
          `}
            hashtag="#audino #ai #audino-ai #youtube #summary #chapters #LanguageAI #AudinoAI #LanguageProcessing #SpeechRecognition #TextToSpeech #AIInnovation   #LanguageServices #TechInnovation #NLP #VoiceTechnology #MultilingualAI #SpeechTech  #DigitalInnovation  #IntelligentAudio  "
          >
            <LinkedinIcon size={25} round />
          </LinkedinShareButton>
          <WhatsappShareButton
            url={"https://ai.audino.in/"}
            quote={`
          🎙️ Introducing Audino - Your Audio Magic Hub! 🚀
          
          Experience the future of language AI with Audino:
          
          Real-time Speech-to-Text in 100 languages 🌐
          Lifelike Text-to-Speech in Arabic, Hindi, and English 🔊
          One platform, endless possibilities! Join Audino today. 🌟
          
          `}
            hashtag="#audino #ai #audino-ai #youtube #summary #chapters #LanguageAI #AudinoAI #LanguageProcessing #SpeechRecognition #TextToSpeech #AIInnovation   #LanguageServices #TechInnovation #NLP #VoiceTechnology #MultilingualAI #SpeechTech  #DigitalInnovation  #IntelligentAudio  "
          >
            <WhatsappIcon size={25} round />
          </WhatsappShareButton>
        </div>
        <div className="flex items-center gap-2">
          {isTranscript && <TranscriptButton />}
          {isReport && <ReportButton />}
        </div>
      </div>
    </div>
  );
}
