import React, { useState } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { EditProfileModal } from "./EditProfileModal";

function ProfileHeader({ userDetail }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen((prev) => !prev);

  return (
    <>
      <div className="bg-white p-6 flex flex-wrap justify-between items-center rounded-lg shadow">
        <div className="flex items-center gap-2 sm:gap-6">
          <img
            className="h-30 w-30 sm:h-12 sm:w-12 rounded-full"
            src={userDetail.picture}
            alt=""
          />
          <div>
            <h1 className="font-medium sm:text-3xl text-lg">
              {userDetail.name}
            </h1>
            <p className="text-gray-500 text-xs sm:text-sm">
              {userDetail.email}
            </p>
          </div>
        </div>
        {/* <button
          className="flex items-center mt-2 sm:mt-0 gap-1 text-white text-sm py-2 px-2.5 rounded-md bg-[#70CBA2]"
          onClick={toggleModal}
        >
          <PencilIcon className="h-4 w-4" />
          Edit Profile
        </button> */}
      </div>

      {/* Render EditProfileModal conditionally */}
      {/* <EditProfileModal isOpen={isOpen} onClose={toggleModal} /> */}
    </>
  );
}

export default ProfileHeader;
