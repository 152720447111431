import axios from "axios";
import authHeader from "./auth-header";
import globalParams from "./global-params";
import toast from "react-hot-toast";

const BASE_URL = process.env.REACT_APP_BACKEND_URL3;

export const getAllMembershipsApi = async () => {
  try {
    const response = await axios.get(BASE_URL + `/memberships`, {
      params: { ...globalParams() },
      headers: { ...authHeader() },
    });
    return response.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const deleteMembershipApi = async (id) => {
  try {
    const response = await axios.delete(BASE_URL + `/memberships/${id}`, {
      params: { ...globalParams() },
      headers: { ...authHeader() },
    });
    return response.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const updateMembershipApi = async ({ newRole, id }) => {
  try {
    const response = await axios.patch(
      BASE_URL + `/memberships/${id}/`,
      { role: newRole },
      {
        params: { ...globalParams() },
        headers: { ...authHeader() },
      }
    );
    if (!response.data.success) {
      throw response.data;
    }

    toast.success("Role updated successfully.");
    return response.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? e.msg ?? "Something went wrong");
  }
};
