import { Fragment, useEffect, useRef, useState } from "react";
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ArrowsPointingInIcon,
  CheckIcon,
  EllipsisVerticalIcon,
  FlagIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import CustomYouTubePlayer from "../../../components/YoutubePlayer/YoutubePlayer";
import secondsToHMS from "../../../utils/secondsToHMS";
import ReportModal from "../../../components/ReportModal/ReportModal";
import dayjs from "dayjs";
import TranscriptionModal from "../../../components/TranscriptModal/TranscriptModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import {
  regenerateSummaryChaptersApi,
  translateSummaryApi,
} from "../../../services/video.services";
import Waveform from "../../../components/AudioPlayer/WaveForm";
import VideoPlayer from "../../../components/VideoPlayer/VideoPlayer";
import LanguageSwitchButton from "../../../components/LanguageSwitch/LanguageSwitch";
import TranslateRequestButton from "../../../components/LanguageSwitch/TranslateRequestButton";

export default function LeftSection({
  youtubeID,
  getVideoDetailQuery,
  videoID,
}) {
  const queryClient = useQueryClient();
  const relativeTime = require("dayjs/plugin/relativeTime"); // Import the plugin
  dayjs.extend(relativeTime); // Extend Day.js with the plugin
  const videoRef = useRef();
  const wavesurferRef = useRef(null);

  const [playing, setPlaying] = useState(false);
  const [preciseSummaryModelOpen, setPreciseSummaryModelOpen] = useState(false);
  const [preciseChapterModelOpen, setPreciseChapterModelOpen] = useState(false);
  const [inputLang, setInputLang] = useState([
    { id: 1, name: "English", online: false },
    { id: 2, name: "Hindi", online: false },
    { id: 3, name: "Arabic", online: false },
  ]);
  const [selected, setSelected] = useState({
    id: 1,
    name: "English",
    online: false,
  });

  const seekToTimestamp = (timeInSecond) => {
    window.scrollTo(0, 0);
    videoRef.current?.seekTo(timeInSecond);
    wavesurferRef.current?.setTime(timeInSecond);
    setPlaying(true);
  };

  const handleDownload = (text) => {
    const element = document.createElement("a");
    const file = new Blob([text], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "audino-ai-generated.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const statuses = {
    completed: "bg-green-400 ring-green-500",
    pending: "bg-yellow-400 ring-yellow-500",
    failed: "bg-red-400 ring-red-500",
  };

  const regenerateSummaryMutation = useMutation({
    mutationFn: regenerateSummaryChaptersApi,
    onSuccess: (data) => {
      toast.success(data.msg);
      const currentVideo = queryClient.getQueryData([
        "getVideoDetail",
        videoID,
      ]);

      queryClient.setQueryData(["getVideoDetail", videoID], {
        ...currentVideo,
        tasks: data.data.tasks,
      });
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const translateSummaryMutation = useMutation({
    mutationFn: translateSummaryApi,
    onSuccess: (data) => {
      toast.success(data.msg);
      const currentVideo = queryClient.getQueryData([
        "getVideoDetail",
        videoID,
      ]);

      queryClient.setQueryData(["getVideoDetail", videoID], {
        ...currentVideo,
        tasks: data.data.tasks,
      });
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleTranslateButton = () => {
    translateSummaryMutation.mutate({ videoID, lang_type: selected.name });
  };

  // const keyMapping = (key) => {
  //   const noneType = {
  //     text: "Text",
  //     start: "Start",
  //     end: "End",
  //     categories: "Categories",
  //   };
  //   return noneType[key] ?? key;
  // };

  const valueMapping = (key, value) => {
    // handle array
    if (typeof value === "object") {
      return (
        <span>
          {Object.entries(value).map(([key, val], ind) => (
            <span className="pr-2">
              {value.length === ind + 1 ? val : val + ","}
            </span>
          ))}
        </span>
      );
    } else {
      return value;
    }
  };

  const convertHinToJSON = (data) => {
    const parsedJSONString = JSON.stringify(data).replace(/&quot;/g, '"');
    const parsedJSON = JSON.parse(parsedJSONString.slice(1, -1));
    return parsedJSON;
  };

  const convertArToJSON = (data) => {
    const parsedJSONString = JSON.stringify(data).replace(/&quot;/g, '"');
    console.log("====================================");
    console.log(data);
    console.log(parsedJSONString);
    console.log("====================================");
  };

  function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (hrs > 0) {
      ret += "" + hrs + " hr " + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + " min " + (secs < 10 ? "0" : "");
    ret += "" + secs + " sec ";

    return ret;
  }

  function capitalizeFirstLetter(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
  }

  const showJson = (data, sub_type, lang_type) => {
    // if (lang_type === "Hindi") {
    //   return (
    //     <div></div>
    // <div>
    //   {JSON.parse(data).map((item, index) => {
    //     return (
    //       <div className="mb-4">
    //         <h2 className="text-md font-bold italic rounded border-2 border-[#70cba2] inline px-2 py-1">
    //           Chunk {index + 1}
    //         </h2>
    //         {Object.entries(convertHinToJSON(item)).map(([key, value]) => (
    //           <div key={key} className="my-2">
    //             <strong>{keyMapping(key)}:</strong> {valueMapping(value)}
    //           </div>
    //         ))}
    //       </div>
    //     );
    //   })}
    // </div>
    // );
    // } else if (lang_type === "Arabic") {
    //   {convertArToJSON(data)}
    // } else {
    return (
      <div>
        {data === "[]" ? (
          <div className="">
            This audio/video is clean our model did not find any suspicious
            speech in this.
          </div>
        ) : (
          JSON.parse(data).map((item, index) => {
            return (
              <div className="mb-4">
                <div className="text-md  italic flex items-center gap-4">
                  <h2 className="font-bold rounded border-2 border-[#70cba2] inline px-2 py-1">
                    Chunk {index + 1}
                  </h2>
                  <h2
                    className="cursor-pointer"
                    onClick={() => seekToTimestamp(item["start"])}
                  >
                    <span className="font-bold">Start: </span>{" "}
                    {fancyTimeFormat(item["start"])}
                  </h2>
                  <h2
                    className="cursor-pointer"
                    onClick={() => seekToTimestamp(item["end"])}
                  >
                    <span className="font-bold">End: </span>{" "}
                    {fancyTimeFormat(item["end"])}
                  </h2>
                </div>

                {item["result"].map((res, index) => {
                  return (
                    <div className="">
                      <div className="mt-2">
                        <strong>Categories:</strong>{" "}
                        <span className="pr-2 text-red-500">
                          {capitalizeFirstLetter(res["criteria"])}
                        </span>
                      </div>
                      <div className="mt-2">
                        <strong>Context:</strong> {res["context"]}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })
        )}
      </div>
    );
    // }
  };

  const renderJson = (sub_type) => {
    if (selected.name === "Hindi") {
      if (getVideoDetailQuery.data?.hin_summary) {
        return showJson(
          getVideoDetailQuery.data?.hin_summary,
          sub_type,
          selected.name
        );
      } else {
        return (
          <TranslateRequestButton
            onTranslate={handleTranslateButton}
            loading={translateSummaryMutation.isLoading}
          />
        );
      }
    } else if (selected.name === "Arabic") {
      if (getVideoDetailQuery.data?.ara_summary) {
        return showJson(
          getVideoDetailQuery.data?.ara_summary,
          sub_type,
          selected.name
        );
      } else {
        return (
          <TranslateRequestButton
            onTranslate={handleTranslateButton}
            loading={translateSummaryMutation.isLoading}
          />
        );
      }
    } else {
      if (getVideoDetailQuery.data?.summary) {
        return showJson(
          getVideoDetailQuery.data?.summary,
          sub_type,
          selected.name
        );
      } else {
        return (
          <TranslateRequestButton
            onTranslate={handleTranslateButton}
            loading={translateSummaryMutation.isLoading}
          />
        );
      }
    }
  };

  useEffect(() => {
    if (!getVideoDetailQuery.isLoading && getVideoDetailQuery.data?.lang_type) {
      if (getVideoDetailQuery.data?.lang_type === "HIN") {
        setSelected(inputLang[1]);
      } else if (getVideoDetailQuery.data?.lang_type === "ARA") {
        setSelected(inputLang[2]);
      } else {
        setSelected(inputLang[0]);
      }
      if (getVideoDetailQuery.data?.summary) {
        const updatedInput = [...inputLang];
        updatedInput[0].online = true;
        setInputLang(updatedInput);
      }
      if (getVideoDetailQuery.data.hin_summary) {
        const updatedInput = [...inputLang];
        updatedInput[1].online = true;
        setInputLang(updatedInput);
      }
      if (getVideoDetailQuery.data?.ara_summary) {
        const updatedInput = [...inputLang];
        updatedInput[2].online = true;
        setInputLang(updatedInput);
      }
    }
  }, [getVideoDetailQuery.isLoading, videoID]);

  return (
    <>
      <div className="overflow-hidden rounded-lg bg-white shadow  p-6">
        {getVideoDetailQuery.data?.upload_type === "audio_url" ? (
          <Waveform
            url={`https://storage.googleapis.com/${process.env.REACT_APP_BUCKET_NAME}/${getVideoDetailQuery.data?.audio_path}`}
            wavesurfer={wavesurferRef}
            isReport={true}
            isTranscript={true}
          />
        ) : getVideoDetailQuery.data?.upload_type === "video_url" ? (
          <VideoPlayer
            url={getVideoDetailQuery.data?.url}
            isReport={true}
            isTranscript={true}
          />
        ) : (
          <CustomYouTubePlayer
            youtubeID={youtubeID}
            videoRef={videoRef}
            playing={playing}
            setPlaying={setPlaying}
            isReport={true}
            isTranscript={true}
          />
        )}
      </div>
      <div className="overflow-hidden rounded-lg bg-white shadow mt-4 p-6">
        <h3 className="text-base font-semibold leading-7 text-gray-900 mb-2">
          {getVideoDetailQuery.isLoading ? (
            <div className="flex-auto rounded-md animate-pulse">
              <div className="w-full h-12 bg-[#70cba2] rounded-md"></div>
            </div>
          ) : (
            getVideoDetailQuery?.data?.yt_meta_data?.title
          )}
        </h3>

        {getVideoDetailQuery.isLoading ? (
          <>
            {[...Array(3).keys()].map((e) => (
              <div className="flex-auto rounded-md animate-pulse mt-2">
                <div className="w-full h-12 bg-[#70cba2] rounded-md"></div>
              </div>
            ))}
          </>
        ) : (
          <>
            {/* <p className="mt-1 max-w-4xl text-sm text-justify leading-6 text-gray-500">
              Youtube Summary:{" "}
              {getVideoDetailQuery.data?.video_metadata?.summary ?? "NA"}
            </p> */}
            {getVideoDetailQuery.data?.upload_type === "youtube" && (
              <p className="mt-1 max-w-4xl text-sm text-justify leading-6 text-gray-500">
                Author: {getVideoDetailQuery.data?.yt_meta_data?.authorUserId}
              </p>
            )}
            {getVideoDetailQuery.data?.upload_type === "youtube" && (
              <p className="mt-1 max-w-4xl text-sm text-justify leading-6 text-gray-500">
                Publish Date:{" "}
                {dayjs(
                  getVideoDetailQuery.data?.yt_meta_data?.publishDate
                ).format("DD MMM YYYY")}
              </p>
            )}
            <p className="mt-1 max-w-4xl text-sm text-justify leading-6 text-gray-500">
              Uploaded {dayjs(getVideoDetailQuery.data?.createdAt).fromNow()} by{" "}
              {getVideoDetailQuery.data?.uploaded_by?.name}
            </p>
          </>
        )}
      </div>
      <div className="overflow-hidden rounded-lg bg-white shadow mt-4 p-6">
        <h3 className="text-base font-semibold leading-7 text-gray-900 mb-2">
          Tasks Overview
        </h3>

        {getVideoDetailQuery.isLoading ? (
          <>
            {[...Array(3).keys()].map((e) => (
              <div className="flex-auto rounded-md animate-pulse mt-2">
                <div className="w-full h-12 bg-[#70cba2] rounded-md"></div>
              </div>
            ))}
          </>
        ) : (
          <ul role="list" className="-mb-8 mt-4">
            {getVideoDetailQuery.data.tasks?.map((task, eventIdx) => (
              <li key={task.task_id}>
                <div className="relative pb-8">
                  {eventIdx !== getVideoDetailQuery.data.tasks.length - 1 ? (
                    <span
                      className="absolute left-1 top-3 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3 items-center">
                    <div
                      className={classNames(
                        statuses[task.task_status],
                        "h-2 w-2 rounded-full ring-1 "
                      )}
                    />

                    {/* <div>
                      <span
                        className={classNames(
                          event.iconBackground,
                          "h-3 w-3 rounded-full flex items-center justify-center  bg-gray-100 ring-1 ring-gray-300 "
                        )}
                      >
                        <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                      </span>
                    </div> */}
                    <div className="flex min-w-0 flex-1 justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          {task.task_title}{" "}
                        </p>
                      </div>
                      <div className="whitespace-nowrap text-right text-sm text-gray-500">
                        <p>{task.task_status}</p>
                        {/* <time dateTime={task.datetime}>{event.date}</time> */}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className=" rounded-lg bg-white shadow mt-4 p-6">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-base font-semibold leading-7 text-gray-900 ">
            Hate Speech Detected:
          </h3>
          <div className="flex gap-2">
            {/* <button
              data-tooltip-id="precise-summary-tooltip"
              data-tooltip-content="Digest"
              onClick={() => setPreciseSummaryModelOpen(true)}
              disabled={getVideoDetailQuery.isLoading}
              className={classNames("text-gray-700", "flex p-2 text-sm")}
            >
              <ArrowsPointingInIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <Tooltip id="precise-summary-tooltip" />
            </button>
            <button
              data-tooltip-id="regenerate-summary-tooltip"
              data-tooltip-content="Regenerate Summary"
              onClick={() =>
                regenerateSummaryMutation.mutate({ id: videoID, type: "S" })
              }
              disabled={getVideoDetailQuery.isLoading}
              className={classNames("text-gray-700", "flex p-2 text-sm")}
            >
              <ArrowPathIcon
                aria-hidden="true"
                className={classNames(
                  "h-5 w-5 text-gray-400",
                  regenerateSummaryMutation.isLoading && "animate-spin"
                )}
              />
              <Tooltip id="regenerate-summary-tooltip" />
            </button> */}
            {/* <LanguageSwitchButton
              selected={selected}
              setSelected={setSelected}
              inputLang={inputLang}
            /> */}
            <button
              data-tooltip-id="download-summary-tooltip"
              data-tooltip-content="Download content"
              onClick={() => handleDownload(getVideoDetailQuery.data?.summary)}
              disabled={getVideoDetailQuery.isLoading}
              className={classNames("text-gray-700", "flex p-2 text-sm w-full")}
            >
              <ArrowDownTrayIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <Tooltip id="download-summary-tooltip" />
            </button>
          </div>
        </div>
        {getVideoDetailQuery.isLoading ? (
          <>
            {[...Array(3).keys()].map((e) => (
              <div className="flex-auto rounded-md animate-pulse mt-2">
                <div className="w-full h-12 bg-[#70cba2] rounded-md"></div>
              </div>
            ))}
          </>
        ) : (
          <p className="mt-1 max-w-4xl text-sm text-justify leading-6 text-gray-500">
            {renderJson(getVideoDetailQuery.data?.content_subcategory)}
          </p>
        )}
      </div>

      {/* report modal */}
      <ReportModal
        feature_id={getVideoDetailQuery.data?._id}
        feature={getVideoDetailQuery.data?.upload_type}
        options={[
          "Audino Generated Summary",
          "Audino Generated Chapters",
          "Audino Query",
          "Others",
        ]}
      />
      {/* transcription modal */}
      <TranscriptionModal videoID={videoID} isButtonDisable={true} />
    </>
  );
}
