import React, { useEffect, useState } from "react";
import EmailModal from "./EmailModal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllUserDataApi } from "../../../services/user.services";
import toast from "react-hot-toast";
import Pagination from "./Pagination";
import { getTemplatesApi } from "../../../services/bulk-email.services";
import SearchBox from "./SearchBox";
import CSVModal from "./CSVModal";
import CardLoader from "../../../components/Loader/CardLoader";

const ListOfUser = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCSVOpen, setIsCSVOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkbox, setCheckBox] = useState({});
  const queryClient = useQueryClient();
  const [csvData, setCsvData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const users = useQuery({
    queryKey: ["users", currentPage, searchText],
    staleTime: Infinity,
    queryFn: () =>
      getAllUserDataApi({ page: currentPage, limit: 10, searchText }),
    onSuccess: (data) => {},
    onError: (err) => toast.error(err.message),
  });

  const templates = useQuery({
    queryKey: ["template"],
    staleTime: 1000 * 60 * 5,
    queryFn: () => getTemplatesApi(),
    onSuccess: (data) => {
      // console.log(data);
    },
    onError: (err) => toast.error(err.message),
  });

  const toggleModal = () => setIsOpen((prev) => !prev);
  const toggleCSV = () => setIsCSVOpen((prev) => !prev);

  const handleSelectAll = () => {
    const currData = queryClient.getQueryData([
      "users",
      currentPage,
      searchText,
    ]);
    const newCheckedState = { ...checkbox };
    if (!isAllChecked) {
      currData?.data?.forEach((element) => {
        newCheckedState[element.email] = {
          email: element.email,
          name: element.name,
        };
      });
    } else {
      currData?.data?.forEach((element) => {
        delete newCheckedState[element.email];
      });
    }
    setCheckBox(newCheckedState);
    setIsAllChecked((prev) => !prev);
  };

  const handleCheckboxChange = (user) => {
    let newCheckedState = { ...checkbox };
    if (user.email in checkbox) {
      delete newCheckedState[user.email];
    } else {
      newCheckedState[user.email] = { email: user.email, name: user.name };
    }
    console.log(newCheckedState);
    setCheckBox(newCheckedState);
  };

  useEffect(() => {
    setIsAllChecked(false);
  }, [currentPage]);

  return (
    <>
      <div className="bg-white rounded-lg py-4 px-6">
        <div className="flex justify-between py-4">
          <h1 className="truncate text-2xl font-bold text-[#616161] ">
            List of Users
          </h1>
          <button
            onClick={toggleModal}
            className="text-white text-sm py-2 px-2.5 rounded-md bg-[#70CBA2]"
          >
            Send Email
          </button>
        </div>
        <div className="flex justify-between py-4">
          <SearchBox setSearchText={setSearchText} />
          <button
            onClick={toggleCSV}
            className="text-white text-sm py-2 px-2.5 rounded-md bg-[#70CBA2]"
          >
            Upload CSV
          </button>
        </div>
        <div className="my-4 overflow-hidden rounded-lg">
          <table className="w-full">
            <thead className="bg-slate-100">
              <tr className="text-left">
                <th className="p-4">
                  <input
                    type="checkbox"
                    className="rounded-md h-3 w-3"
                    checked={isAllChecked}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className="p-2">Name</th>
                <th className="p-2 w-2/5">Email</th>
              </tr>
            </thead>
            <tbody>
              {users?.isLoading
                ? [...Array(5).keys()].map((load) => (
                    <CardLoader key={`CardLoader-${load}`} />
                  ))
                : users?.data?.data?.map((user) => {
                    return (
                      <tr key={user._id} className="border-t border-gray-200">
                        <td className="px-4">
                          <input
                            type="checkbox"
                            className="rounded-md h-3 w-3"
                            checked={user.email in checkbox}
                            onChange={() => handleCheckboxChange(user)}
                          />
                        </td>
                        <td className="p-2">{user.name}</td>
                        <td className="p-2">
                          <span className="block">{user?.email}</span>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
        <Pagination
          totalPages={users?.data?.totalPages}
          page={currentPage}
          setPage={setCurrentPage}
        />
        <EmailModal
          isOpen={isOpen}
          toggleModal={toggleModal}
          checkbox={checkbox}
          templates={templates?.data}
          csvData={csvData}
        />
        <CSVModal
          isOpen={isCSVOpen}
          toggleModal={toggleCSV}
          setCsvData={setCsvData}
        />
      </div>
    </>
  );
};

export default ListOfUser;
