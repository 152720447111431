import axios from "axios";
import authHeader from "./auth-header";
import { autoLogout } from "../utils/logout";
import globalParams from "./global-params";

const BASE_URL3 = process.env.REACT_APP_BACKEND_URL3;

export const postReportRequestApi = async (data) => {
    try {
      const res = await axios.post(BASE_URL3 + "/report/create", data, {
        headers: { ...authHeader() },
        params:{...globalParams()},
      });
      return res.data;
    } catch (e) {
      autoLogout(e)
      throw Error(e.response?.data.msg ?? "Something went wrong");
    }
  };
  