import { useEffect, useState } from "react";
import AppBar from "../../components/AppBar/AppBar";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CustomInput from "../../components/CustomInput/CustomInput";
import AddOrganizationLoader from "./components/AddOrganizationLoader";
// import { organizationAllValidation } from "../../validation/allValidation";
// import { organizationSingleFieldValidation } from "../../validation/singleValidation";
// import useSingleFieldValidation from "../../utils/inputDebounce";
import InviteMemberModal from "./components/InviteMemberModal/InviteMemberModal";
import {
  createOrganizationApi,
  updateOrganizationApi,
  fetchOrganizationApi,
} from "../../services/organization.services";
import { getAllMembershipsApi } from "../../services/membership.services";
import { createInvitationApi } from "../../services/invitation.services";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useMembershipStore } from "../../store/memberships";
import MembershipCard from "./components/MembershipCard/MembershipCard";
import CardLoader from "../../components/Loader/CardLoader";
import Footer from "../../components/Footer/Footer";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { organizationAllValidation } from "../../validation/allValidation";
import { toast } from "react-hot-toast";

const initialData = {
  slug: "",
  name: "",
  description: "",
  contact: {
    email: "",
    mobileNumber: "",
    location: "",
  },
};
export default function AddOrganizationPage() {
  const { id: orgId } = useParams();
  const [formValue, setFormValue] = useState(initialData);
  const [formError, setFormError] = useState({
    slug: null,
    name: null,
    description: null,
    contact: {
      email: null,
      mobileNumber: null,
      location: null,
    },
  });
  // const { debouncedValidation } = useSingleFieldValidation(
  //   // organizationSingleFieldValidation,
  //   true,
  //   1000,
  //   formError,
  //   setFormError
  // );

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const setMemberships = useMembershipStore((state) => state.setMemberships);
  const memberships_obj = useMembershipStore((state) => state.memberships_obj);

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const handleInviteSuccess = (data) => {
    createInvitationMutation.mutate({
      invitationData: data,
    });
  };

  const createInvitationMutation = useMutation({
    mutationFn: createInvitationApi,
    onSuccess: (data) => {
      setIsInviteModalOpen(false);
      refetchMemberships();
    },
    onError: (err) => toast.error(err.message),
  });

  const handleInviteCancel = () => {
    setIsInviteModalOpen(false);
  };

  const handleInviteClick = () => {
    setIsInviteModalOpen(true);
  };

  const handleSave = () => {
    const { isValid, error } = organizationAllValidation(formValue);

    if (isValid) {
      if (orgId) {
        // updateOrganizationMutation
        updateOrganizationMutation.mutate({
          data: {
            slug: formValue.slug,
            name: formValue.name,
            description: formValue.description,
            contact: {
              email: formValue.contact.email,
              mobileNumber: formValue.contact.mobileNumber,
              location: formValue.contact.location,
            },
          },
          id: orgId,
        });
      } else {
        addOrganizationMutation.mutate({
          data: {
            slug: formValue.slug,
            name: formValue.name,
            description: formValue.description,
            contact: {
              email: formValue.contact.email,
              mobileNumber: formValue.contact.mobileNumber,
              location: formValue.contact.location,
            },
          },
        });
      }
    } else {
      setFormError(error);
    }
  };

  const addOrganizationMutation = useMutation({
    mutationFn: createOrganizationApi,
    onSuccess: (data) => {
      navigate("/organizations");
      const orgnaizations = queryClient.getQueryData(["organizations"]);
      queryClient.setQueryData(["organizations"], [...orgnaizations, data]);
    },
    onError: (err) => toast.error(err.message),
  });

  const updateOrganizationMutation = useMutation({
    mutationFn: updateOrganizationApi,
    onSuccess: (data) => {
      navigate("/organizations");
    },
    onError: (err) => toast.error(err.message),
  });

  const handleInputChange = (name, value) => {
    setFormValue((prev) => {
      if (name.includes("contact.")) {
        const contactField = name.split(".")[1];
        return {
          ...prev,
          contact: {
            ...prev.contact,
            [contactField]: value,
          },
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
    // debouncedValidation({ name, value });
  };

  const { refetch: refetchOrganization } = useQuery({
    queryKey: ["organization", orgId],
    staleTime: Infinity,
    enabled: false,
    queryFn: () => fetchOrganizationApi({ id: orgId }),
    onSuccess: (fetchedData) => {
      setFormValue({
        slug: fetchedData.slug || "",
        name: fetchedData.name || "",
        description: fetchedData.description || "",
        contact: {
          email: fetchedData.contact?.email || "",
          mobileNumber: fetchedData.contact?.mobileNumber || "",
          location: fetchedData.contact?.location || "",
        },
      });
    },
    onError: (err) => toast.error(err.message),
  });

  const {
    refetch: refetchMemberships,
    isLoading: membershipsLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["memberships", orgId],
    enabled: false,

    queryFn: () => getAllMembershipsApi(),
    onSuccess: (data) => setMemberships(data),
    onError: (err) => toast.error(err.message),
  });

  useEffect(() => {
    //fetch if orgId present , fetch the form data,and set in formValue
    if (orgId != null) {
      refetchOrganization();

      refetchMemberships();
    } else setFormValue(formValue);
  }, [orgId]);

  return (
    <>
      <div className="min-h-full">
        <AppBar />

        <main className="pt-8 pb-8 bg-[#e4f5ed] min-h-screen">
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h2
              className="truncate text-2xl font-bold text-gray-700"
              id="section-1-title"
            >
              {orgId ? "Updating " : "Create "} Organization
            </h2>
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-1 lg:gap-8 ">
              {/* Invite members */}
              <ul className="mt-4">
                {orgId ? (
                  membershipsLoading || isRefetching ? (
                    [...Array(2).keys()].map((load) => (
                      <li
                        className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white py-8 sm:py-0 mb-4"
                        key={`CardLoader-${load}`}
                      >
                        <CardLoader />
                      </li>
                    ))
                  ) : (
                    <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6 min-h-full">
                      <div className="mb-4 bg-white">
                        <button
                          type="button"
                          className="flex items-center gap-x-2 ml-auto rounded-md bg-[#70cba2] px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm cursor-pointer"
                          onClick={handleInviteClick}
                        >
                          Invite Members
                          <PlusIcon
                            className="-mr-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>

                        {/* Render Members */}
                        {memberships_obj.map((membership) => (
                          <MembershipCard
                            key={membership._id}
                            membership={membership}
                            onMembershipUpdate={refetchMemberships}
                          />
                        ))}
                      </div>
                    </div>
                  )
                ) : null}
              </ul>

              {/* Form fileds */}
              <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6 min-h-full">
                <div>
                  <div className="mb-4">
                    <label
                      htmlFor="slug"
                      className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Short Name <span className="text-red-600">*</span>
                    </label>
                    <CustomInput
                      type="text"
                      name="slug"
                      id="slug"
                      formError={formError}
                      placeholder="Slug"
                      value={formValue.slug}
                      onChange={(e) =>
                        handleInputChange("slug", e.target.value)
                      }
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Full Name
                    </label>
                    <CustomInput
                      type="text"
                      name="name"
                      id="name"
                      formError={formError}
                      placeholder="Org 1"
                      value={formValue.name}
                      onChange={(e) =>
                        handleInputChange("name", e.target.value)
                      }
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Description
                    </label>
                    <CustomInput
                      type="text"
                      inputType="textarea"
                      name="description"
                      id="description"
                      formError={formError}
                      placeholder="Organization description"
                      value={formValue.description}
                      onChange={(e) =>
                        handleInputChange("description", e.target.value)
                      }
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Email
                    </label>
                    <CustomInput
                      type="email"
                      name="contact.email"
                      id="email"
                      formError={formError}
                      placeholder="Email"
                      value={formValue.contact.email}
                      onChange={(e) =>
                        handleInputChange("contact.email", e.target.value)
                      }
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="mobileNumber"
                      className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Mobile Number
                    </label>

                    <CustomInput
                      type="number"
                      name="contact.mobileNumber"
                      id="mobileNumber"
                      formError={formError}
                      placeholder="+91 1234567890"
                      value={formValue.contact.mobileNumber}
                      onChange={(e) =>
                        handleInputChange(
                          "contact.mobileNumber",
                          e.target.value
                        )
                      }
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="Location"
                      className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Location
                    </label>
                    <CustomInput
                      type="text"
                      name="contact.location"
                      id="location"
                      formError={formError}
                      placeholder="Location"
                      value={formValue.contact.location}
                      onChange={(e) =>
                        handleInputChange("contact.location", e.target.value)
                      }
                    />
                  </div>

                  {/* Action buttons */}
                  {false ? (
                    <AddOrganizationLoader />
                  ) : (
                    <div className="flex-shrink-0 border-t border-gray-200 mt-8 pt-4">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </button>
                        <PrimaryButton
                          onClick={handleSave}
                          loading={
                            addOrganizationMutation.isLoading ||
                            updateOrganizationMutation.isLoading
                          }
                        >
                          Save
                        </PrimaryButton>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </main>
      </div>
      {/* invite member form */}
      <InviteMemberModal
        open={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        onCancel={handleInviteCancel}
        onSuccess={handleInviteSuccess}
        isLoading={createInvitationMutation.isLoading}
      />
    </>
  );
}
