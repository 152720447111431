import AppBar from "../../components/AppBar/AppBar";
import LeftSection from "./components/LeftSection";
import Footer from "../../components/Footer/Footer";

export default function FeaturesPage() {
  return (
    <>
      {" "}
      <div className="min-h-full">
        <AppBar />

        <main className="pt-8 pb-8 bg-[#e4f5ed] min-h-screen">
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="sr-only">Features page</h1>

            <section aria-labelledby="section-1-title">
              <h2 className="sr-only" id="section-1-title">
                features section
              </h2>
              <LeftSection />
            </section>
          <Footer />
          </div>
        </main>
      </div>
    </>
  );
}
