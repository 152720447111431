import ReportButton from "../ReportModal/ReportButton";
import TranscriptButton from "../TranscriptModal/TranscriptButton";

export default function VideoPlayer({
  url,
  isTranscript = false,
  isReport = false,
}) {
  return (
    <div>

    <video width="100%" controls>
      <source src={url} type="video/mp4" />
      Your browser does not support HTML video.
    </video>
    <div className="flex items-center gap-2 justify-end mt-4">
        {isTranscript && <TranscriptButton />}
        {isReport && <ReportButton />}
      </div>
    </div>
  );
}
