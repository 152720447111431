import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { sendEmailApi } from "../../../services/bulk-email.services";

// Register the image resize module with Quill
Quill.register("modules/imageResize", ImageResize);

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline", "strike"],
    ["link", "image"],
    [{ align: [] }, { color: [] }, { background: [] }],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
  imageResize: {},
};

const formats = [
  "header",
  "font",
  "list",
  "bullet",
  "bold",
  "italic",
  "underline",
  "strike",
  "link",
  "image",
  "align",
  "color",
  "background",
];

const EmailModal = ({ isOpen, toggleModal, checkbox, templates, csvData }) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState(""); // need to have placeholders like ${name} etc which will be matched to data
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [sendData, setSendData] = useState([]);

  useEffect(() => {
    if (isOpen) {
      let data = [];
      Object.keys(checkbox).forEach((key) => {
        data.push(checkbox[key]);
      });
      data.push(...csvData);
      setSendData(data);
    }
  }, [isOpen]);

  const handleSendEmail = () => {
    sendEmailMutation.mutate({
      body: {
        data: sendData,
        subject,
        message,
        id: selectedTemplate,
      },
    });

    toggleModal();
  };
  const sendEmailMutation = useMutation({
    mutationFn: sendEmailApi,
    onSuccess: (data) => {
      toast.success("Emails Sent");
      // window.location.reload();
    },
    onError: (err) => toast.error(err.message),
  });

  const handleTemplateChange = (e) => {
    if (e.target.value === "") {
      setSelectedTemplate("");
    } else {
      setSelectedTemplate(e.target.value);
      setMessage("");
      setSubject("");
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={toggleModal}
      >
        <div className="flex items-center justify-center min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-lg">
              <div className="space-y-4">
                <div className="col-span-1 w-45 sm:col-span-2 md:col-span-2 align-left ">
                  <select
                    className={`py-2 border border-gray-300 focus:border-green-100 block w-full text-green-600 rounded-md focus:ring-green-400`}
                    value={selectedTemplate}
                    onChange={handleTemplateChange}
                  >
                    <option value="" className="">
                      Use Custom Template
                    </option>
                    {templates?.map((option) => {
                      return (
                        <option
                          key={option._id}
                          value={option._id}
                          className=""
                        >
                          {option.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <div className=" text-sm font-medium text-gray-700 pb-2">
                    To Users
                  </div>
                  <div className="flex flex-wrap">
                    {sendData.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className="mr-2 px-1 mb-1 border border-gray-300 text-gray-500 rounded-md"
                        >
                          {element.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {selectedTemplate === "" ? (
                  <div>
                    <div className="space-y-2">
                      <label
                        htmlFor="subject"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Subject
                      </label>
                      <input
                        type="text"
                        id="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        placeholder="Type your subject here..."
                        className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      />
                    </div>
                    <div className="space-y-2">
                      <label
                        htmlFor="message"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Message
                      </label>
                      <div className="">
                        <ReactQuill
                          value={message}
                          onChange={setMessage}
                          modules={modules}
                          formats={formats}
                          placeholder="Type your message here..."
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="flex justify-center">
                  <button
                    onClick={handleSendEmail}
                    className="bg-[#70CBA2] font-semibold text-white py-2 px-4 rounded-md  focus:outline-none"
                  >
                    Send Email
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EmailModal;
