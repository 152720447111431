import React, { useState } from "react";

const ToggleButton = ({ firstField, secondField, handleClick }) => {
  const [selectedField, setSelectedField] = useState(true);
  const setSelected1st = () => {
    setSelectedField(true);
    handleClick(false);
  };
  const setSelected2nd = () => {
    setSelectedField(false);
    handleClick(true);
  };

  return (
    <div>
      <div className="inline-flex rounded-sm shadow-sm" role="group">
        <button
          type="button"
          className={
            "px-4 py-2 text-sm font-sm " +
            (selectedField
              ? "text-white bg-[#70cba2]"
              : "text-black-200 bg-white") +
            " border border-gray-200 rounded-s-md hover:bg-[#70cba2] hover:text-white "
          }
          onClick={setSelected1st}
        >
          {firstField}
        </button>
        <button
          type="button"
          className={
            "px-4 py-2 text-sm font-medium " +
            (selectedField
              ? "text-black-200 bg-white"
              : "text-white bg-[#70cba2]") +
            " border border-gray-200 rounded-e-md hover:bg-[#70cba2] hover:text-white "
          }
          onClick={setSelected2nd}
        >
          {secondField}
        </button>
      </div>
    </div>
  );
};

export default ToggleButton;
