import React from "react";
import ContentBox from "./ContentBox";
import CardLoader from "../../../components/Loader/CardLoader";

function OrgSubscriptionList({ orgSubscriptionsQuery }) {
  return (
    <div>
      {orgSubscriptionsQuery?.isLoading
        ? [...Array(5).keys()].map((load) => (
            <CardLoader key={`CardLoader-${load}`} />
          ))
        : orgSubscriptionsQuery?.data?.map((subscription) => {
            return (
              <ContentBox
                key={subscription._id}
                title={subscription.name}
                type={true}
                isOrgOrUser={true}
                id={subscription._id}
              />
            );
          })}
    </div>
  );
}

export default OrgSubscriptionList;
