import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL3 = process.env.REACT_APP_BACKEND_URL3;

export const sendEmailApi = async ({ body }) => {
  try {
    const res = await axios.post(
      BASE_URL3 + "/email-template/send-email",
      body,
      {
        headers: { ...authHeader() },
      }
    );
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getTemplatesApi = async () => {
  try {
    const res = await axios.get(BASE_URL3 + "/email-template/", {
      headers: { ...authHeader() },
    });
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const sendCsvApi = async (data) => {
  try {
    const res = await axios.post(
      BASE_URL3 + "/email-template/convert-csv/",
      data,
      {
        headers: { ...authHeader() },
      }
    );
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};
