import React from "react";
import Pagination from "./Pagination";
import ContentBox from "./ContentBox";
import CardLoader from "../../../components/Loader/CardLoader";

function OrganizationsList({
  organizationsQuery,
  orgSubscriptionsQuery,
  page,
  setPage,
}) {
  return (
    <div>
      {organizationsQuery?.isLoading || orgSubscriptionsQuery?.isLoading
        ? [...Array(5).keys()].map((load) => (
            <CardLoader key={`CardLoader-${load}`} />
          ))
        : organizationsQuery?.data?.data?.map((organization) => {
            return (
              <ContentBox
                key={organization._id}
                title={organization.slug}
                type={false}
                isOrgOrUser={true}
                id={organization._id}
                subOptions={orgSubscriptionsQuery?.data}
                selectedSubscription={organization.subscriptions}
              />
            );
          })}
      <Pagination
        totalPages={organizationsQuery?.data?.totalPages}
        setPage={setPage}
        page={page}
      />
    </div>
  );
}

export default OrganizationsList;
