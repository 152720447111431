import { create } from "zustand";

export const useVideoStore = create((set) => ({
  chatActivity: [
    // {
    //   id: 1,
    //   type: "started",
    //   person: { name: "Rohan Kumar" },
    //   dateTime: "",
    // },
  ],

  setChatActivity: (data) =>
    set((state) => ({ chatActivity: data})),
}));
