import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = process.env.REACT_APP_BACKEND_URL3;

export const createOrganizationApi = async ({ data }) => {
  try {
    const res = await axios.post(BASE_URL + "/organizations", data, {
      headers: { ...authHeader() },
    });
    if (!res.data.success) {
      throw res.data;
    }
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? e.msg ?? "Something went wrong");
  }
};

export const updateOrganizationApi = async ({ data, id }) => {
  try {
    const res = await axios.patch(BASE_URL + `/organizations/${id}`, data, {
      headers: { ...authHeader() },
    });
    return res.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const fetchOrganizationApi = async ({ id = "" }) => {
  try {
    const response = await axios.get(BASE_URL + `/organizations/${id}`, {
      headers: { ...authHeader() },
    });
    return response.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const fetchOrganizationsApi = async (data) => {
  try {
    const response = await axios.get(BASE_URL + "/organizations", {
      params: {
        page: data?.page,
        page_size: data?.page_size,
        filter: data?.filter,
        search: data?.searchValue,
      },
      headers: { ...authHeader() },
    });

    return response.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAllOrganizationsApi = async ({ page, limit, searchText }) => {
  try {
    const res = await axios.get(BASE_URL + "/organizations/all", {
      headers: { ...authHeader() },
      params: {
        page,
        limit,
        searchText,
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const deleteOrganizationApi = async ({ id }) => {
  try {
    const response = await axios.delete(BASE_URL + `/organizations/${id}`, {
      headers: { ...authHeader() },
    });
    return response.data.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};
