import { create } from "zustand";

var localStorageToken = localStorage.getItem("audino-ai-token");
var localStorageUser = localStorage.getItem("audino-ai-user");

export const useUserStore = create((set) => ({
  token: localStorageToken ?? "",
  userDetail: localStorageUser
    ? JSON.parse(localStorageUser)
    : {
        user_id: "",
        userType: "u",
        name: "User",
        picture: "https://picsum.photos/seed/picsum/200/300",
        email: "",
      },

  setToken: (data) =>
    set((state) => {
      localStorage.setItem("audino-ai-token", data);
      return {
        ...state,
        token: data,
      };
    }),

  setUserDetail: (data) =>
    set((state) => {
      if (!data.userType) data.userType = "u";
      localStorage.setItem("audino-ai-user", JSON.stringify(data));
      console.log("data", data);
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          name: data.name,
          picture: data.picture,
          email: data.email,
          user_id: data._id,
          userType: data.userType,
        },
      };
    }),
}));
