import { nanoid } from "nanoid";

export default function authHeader() {
  const audinoAiToken = localStorage.getItem("audino-ai-token");

  if (audinoAiToken) {
    return { "x-auth-token": audinoAiToken };
  } else {
    return {};
  }
}
