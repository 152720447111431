import React from "react";

function SubscriptionInfo({ planName, data, organisationData }) {
  function addSpaceAndCapitalize(str) {
    if (!str) return str;

    let spacedStr = str
      .replace(/editorlimit/i, "Editor Limit")
      .replace(/viewerlimit/i, "Viewer Limit");
    return spacedStr
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  }

  return (
    <div className="bg-white px-6 py-2 rounded-lg shadow">
      <h2 className="text-xl font-medium">
        Plan Name: <span className="text-[#585858] text-md">{planName}</span>
      </h2>
      <div>
        <div className="py-2 flex flex-wrap">
          {data.map((item) => {
            return (
              <div
                key={item.key.field}
                className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-1"
              >
                <p className="font-medium text-sm text-[#4F5155] bg-gray-100 p-4 rounded-lg h-full">
                  {addSpaceAndCapitalize(item.key.field)}
                  <span className="block text-base text-black">
                    {item.key.value}
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="py-2 ">
        {organisationData?.map((element) => {
          return (
            <>
              <h2 key={element.orgId} className="text-xl font-medium">
                Organisation:{" "}
                <span key={element.orgId} className="text-[#585858] text-md">
                  {element.Name}
                </span>
              </h2>
              <div className="flex pb-4">
                {Object.keys(element.totalLimit).map((key) => {
                  return (
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-1">
                      <p className="font-medium text-sm text-[#4F5155] bg-gray-100 p-4 rounded-lg h-full">
                        {addSpaceAndCapitalize(key)}
                        <span className="block text-base text-black">
                          {key === "duration"
                            ? `${element.used[key] / 60}/${
                                element.totalLimit[key] / 60
                              } minutes`
                            : `${element.used[key]}/${element.totalLimit[key]}`}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
      {/* <div className="flex justify-end py-2 border-t border-gray-200">
        <button className="text-white text-sm py-2 px-2.5 rounded-md bg-[#70CBA2]">
          Add New Plan
        </button>
      </div> */}
    </div>
  );
}

export default SubscriptionInfo;
