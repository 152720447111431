import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import { Toaster } from "react-hot-toast";
import HomePage from "./pages/HomePage/HomePage.";
import LoginPage from "./pages/LoginPage/LoginPage";
import SignupPage from "./pages/SignupPage/SignupPage";

import  React, { useEffect, useState } from "react";

import AudinoRoutes from "./routes/AudinoRoutes";

function App() {
  // const [ user, setUser ] = useState([]);
  // const login = useGoogleLogin({
  //     onSuccess: (codeResponse) => setUser(codeResponse),
  //     onError: (error) => console.log('Login Failed:', error)
  // });

  // useEffect(
  //     () => {
  //         if (user) {
  //             axios
  //                 .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
  //                     headers: {
  //                         Authorization: `Bearer ${user.access_token}`,
  //                         Accept: 'application/json'
  //                     }
  //                 })
  //                 .then((res) => {
  //                     setProfile(res.data);
  //                 })
  //                 .catch((err) => console.log(err));
  //         }
  //     },
  //     [ user ]
  // );

  // log out function to log the user out of google and set the profile array to null
  // const logOut = () => {
  //     // googleLogout();
  //     localStorage.removeItem('audino-ai-token')
  //     window.location.reload()
  // };
  // useEffect(() => {
  //   if (!localStorage.getItem('audino-ai-token')) {
  //     window.location.href  = '/'
  //   }
  // }, []);



  return (
    <BrowserRouter>
      {CustomToast()}
      <Routes>
        {/* <Route
          path="/login"
          exact
          element={
            <LoginPage/>
          }
        />
        <Route
          path="/signup"
          exact
          element={
            <SignupPage />
          }
        /> */}
        {/* <Route
          path="/*"
          element={
            <Root />
          }
        />  */}
        <Route path="/" element={<HomePage />} />{" "}
        <Route path="/*" element={<AudinoRoutes />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

const CustomToast = () => {
  return createPortal(
    <Toaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      closeOnClick
      containerClassName=""
      containerStyle={{
        position: "fixed",
        // zIndex: "999999"
      }}
      toastOptions={{
        // Define default options
        className: "toaster-style text-sm rounded-md",
        duration: 5000,
        style: {
          background: "#333",
          color: "#fff",
        },

        // Default options for specific types
        success: {
          style: {
            background: "#f0fdf4",
            color: "#15803d",
          },
        },
        error: {
          style: {
            background: "#fef2f2",
            color: "#b91c1c",
          },
        },
      }}
    />,
    document.getElementById("alert-modal")
  );
};
