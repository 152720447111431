import React from "react";
import { getSubscriptionsApi } from "../../services/subscription.services";
import AppBar from "../../components/AppBar/AppBar";
import Footer from "../../components/Footer/Footer";
import LeftSection from "./components/ContentPage";
import { getAllOrganizationsApi } from "../../services/organization.services";
import { getAllUserDataApi } from "../../services/user.services";
import { useDataQuery } from "./hooks/useDataQuery";

function SubscriptionPage() {
  return (
    <div className="min-h-full">
      <AppBar />

      <main className="pt-8 pb-8 bg-[#e4f5ed] min-h-screen">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="sr-only">Subscription page</h1>

          <section aria-labelledby="section-1-title">
            <h2 className="sr-only" id="section-1-title">
              Subscription Section
            </h2>
            <LeftSection />
          </section>
          <Footer />
        </div>
      </main>
    </div>
  );
}

export default SubscriptionPage;
