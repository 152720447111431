import axios from "axios";
import authHeader from "./auth-header";
import { autoLogout } from "../utils/logout";
import globalParams from "./global-params";

const BASE_URL3 = process.env.REACT_APP_BACKEND_URL3;

export const googleAuth = async (data) => {
  try {
    const res = await axios.post(
      BASE_URL3 + "/user/googlelogin",
      {},
      {
        headers: {
          "x-login-token": data,
        },
      }
    );
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getUserData = async () => {
  try {
    const res = await axios.get(BASE_URL3 + "/user/me", {
      headers: {
        ...authHeader(),
      },
      params: { ...globalParams() },
    });
    return res.data.data;
  } catch (e) {
    autoLogout(e);
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getAllUserDataApi = async ({ page, limit, searchText }) => {
  try {
    const res = await axios.get(BASE_URL3 + "/user", {
      headers: {
        ...authHeader(),
      },
      params: {
        page,
        limit,
        searchText,
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const getQuotaApi = async ({ user }) => {
  try {
    const res = await axios.post(BASE_URL3 + "/sub-user-mapping/quota", user, {
      headers: {
        ...authHeader(),
      },
    });
    return res.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};
