import React, { useEffect, useState } from "react";
import AppBar from "../../components/AppBar/AppBar";
import Footer from "../../components/Footer/Footer";
import CustomInput from "../../components/CustomInput/CustomInput";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { subscriptionAllValidation } from "../../validation/allValidation";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createSubscriptionApi,
  getSingleSubscriptionAPI,
  updateSubscriptionApi,
} from "../../services/subscription.services";
import { toast } from "react-hot-toast";
import { useNavigate, useLocation, useParams } from "react-router";

const initialData = {
  name: "",
  description: "",
  price: 0,
  features: {
    videoUploadLimit: 1,
    video_duration: 240,
    viewerLimit: 2,
    editorLimit: 2,
    chatMsgLimitPerVideo: 5,
    maxRegreatePerVideo: 2,
    maxRegreateChapterPerVideo: 2,
    saveAndRegenerate: 2,
    maxOrgsPerUser: 2,
  },
};

function AddSubscriptionPage() {
  const { id: subId } = useParams();
  const [formValue, setFormValue] = useState(initialData);
  const [formError, setFormError] = useState({
    name: null,
    description: null,
    price: null,
    features: {
      video_duration: null,
      viewerLimit: null,
      editorLimit: null,
      chatMsgLimitPerVideo: null,
      maxRegreatePerVideo: null,
      maxRegreateChapterPerVideo: null,
      saveAndRegenerate: null,
      maxOrgsPerUser: null,
    },
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const orgPresent = location.state?.orgPresent;

  const handleInputChange = (name, value) => {
    setFormValue((prev) => {
      if (name.includes("features.")) {
        const featureField = name.split(".")[1];

        return {
          ...prev,
          features: {
            ...prev.features,
            [featureField]: value,
          },
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  const type = orgPresent ? "org" : "user";
  const handleSave = () => {
    const { isValid, error } = subscriptionAllValidation(formValue);

    if (isValid) {
      let features;
      if (type === "org") {
        features = {
          video_durationOrg: formValue.features.video_duration,
          viewerLimitPerOrg: formValue.features.viewerLimit,
          editorLimitPerOrg: formValue.features.editorLimit,
          chatMsgLimitPerVideoOrg: formValue.features.chatMsgLimitPerVideo,
          maxRegreatePerVideoPerOrg: formValue.features.maxRegreatePerVideo,
          maxRegreateChapterPerVideoPerOrg:
            formValue.features.maxRegreateChapterPerVideo,
          saveAndRegeneratePerOrg: formValue.features.saveAndRegenerate,
        };
      } else {
        features = {
          video_duration: formValue.features.video_duration,
          viewerLimitPerUser: formValue.features.viewerLimit,
          editorLimitPerUser: formValue.features.editorLimit,
          chatMsgLimitPerVideo: formValue.features.chatMsgLimitPerVideo,
          maxRegreatePerVideo: formValue.features.maxRegreatePerVideo,
          maxRegreateChapterPerVideo:
            formValue.features.maxRegreateChapterPerVideo,
          saveAndRegenerate: formValue.features.saveAndRegenerate,
          maxOrgsPerUser: formValue.features.maxOrgsPerUser,
        };
      }
      if (!subId) {
        addSubscriptionMutation.mutate({
          data: {
            name: formValue.name,
            description: formValue.description,
            price: formValue.price,
            features,
          },
          type: type,
        });
      } else {
        updateSubscriptionMutation.mutate({
          data: {
            name: formValue.name,
            description: formValue.description,
            price: formValue.price,
            features,
            subId: subId,
          },
          type: type,
        });
      }
    } else {
      setFormError(error);
    }
  };

  const addSubscriptionMutation = useMutation({
    mutationFn: createSubscriptionApi,
    onSuccess: (data) => {
      navigate("/subscriptions");
      if (type === "user") {
        const subscriptions = queryClient.getQueryData(["userSubscriptions"]);
        queryClient.setQueryData(
          ["userSubscriptions"],
          [...subscriptions, data]
        );
      } else {
        const subscriptions = queryClient.getQueryData(["orgSubscriptions"]);
        queryClient.setQueryData(
          ["orgSubscriptions"],
          [...subscriptions, data]
        );
      }
      toast.success("Subscription Created");
    },
    onError: (err) => toast.error(err.message),
  });

  const updateSubscriptionMutation = useMutation({
    mutationFn: updateSubscriptionApi,
    onSuccess: (data) => {
      navigate("/subscriptions");
      toast.success("Subscription Updated");
    },
    onError: (err) => toast.error(err.message),
  });

  const { refetch: refetchSubscription } = useQuery({
    queryKey: ["subcription", subId],
    staleTime: Infinity,
    enabled: false,
    queryFn: () => getSingleSubscriptionAPI({ id: subId, type: type }),
    onSuccess: (fetchedData) => {
      setFormValue({
        name: fetchedData.name || "",
        description: fetchedData.description || "",
        price: fetchedData.price || 0,
        features: {
          video_duration:
            type === "user"
              ? fetchedData.features.video_duration
              : fetchedData.features.video_durationOrg || 0,
          viewerLimit:
            type === "user"
              ? fetchedData.features.editorLimitPerUser
              : fetchedData.features.viewerLimitPerOrg || 0,
          editorLimit:
            type === "user"
              ? fetchedData.features.editorLimitPerUser
              : fetchedData.features.editorLimitPerOrg || 0,
          chatMsgLimitPerVideo:
            type === "user"
              ? fetchedData.features.chatMsgLimitPerVideo
              : fetchedData.features.chatMsgLimitPerVideoOrg || 0,
          maxOrgsPerUser:
            type === "user" ? fetchedData.features.maxOrgsPerUser : 0,
          maxRegreatePerVideo:
            type === "user"
              ? fetchedData.features.maxRegreatePerVideo
              : fetchedData.features.maxRegreatePerVideoPerOrg || 0,
          maxRegreateChapterPerVideo:
            type === "user"
              ? fetchedData.features.maxRegreateChapterPerVideo
              : fetchedData.features.maxRegreateChapterPerVideoPerOrg || 0,
          saveAndRegenerate:
            type === "user"
              ? fetchedData.features.saveAndRegenerate
              : fetchedData.features.saveAndRegeneratePerOrg || 0,
        },
      });
    },
    onError: (err) => toast.error(err.message),
  });

  useEffect(() => {
    if (subId != null) {
      refetchSubscription();
    }
  }, [subId]);
  return (
    <>
      <div className="min-h-full">
        <AppBar />
        <main className="pt-8 pb-8 bg-[#e4f5ed] min-h-screen">
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h2
              className="truncate text-2xl font-bold text-gray-700 mb-10"
              id="section-1-title"
            >
              {subId ? "Updating " : "Create "} Subscription
            </h2>
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-1 lg:gap-8 ">
              {/* Form fileds */}
              <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6 min-h-full">
                <div>
                  <div className="mb-4">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Name <span className="text-red-600">*</span>
                    </label>
                    <CustomInput
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                      value={formValue.name}
                      formError={formError}
                      onChange={(e) =>
                        handleInputChange("name", e.target.value)
                      }
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Description
                    </label>
                    <CustomInput
                      type="text"
                      inputType="textarea"
                      name="description"
                      id="description"
                      placeholder={"Description"}
                      formError={formError}
                      value={formValue.description}
                      onChange={(e) =>
                        handleInputChange("description", e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="price"
                      className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                    >
                      Price
                    </label>
                    <CustomInput
                      type="number"
                      name="price"
                      id="price"
                      placeholder={"₹00.00"}
                      formError={formError}
                      value={formValue.price}
                      onChange={(e) =>
                        handleInputChange("price", Number(e.target.value))
                      }
                    />
                    <div className="py-2 mt-4">
                      <h2 className="font-semibold">Features</h2>

                      <div className="grid grid-cols-3 gap-4 mt-4">
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          >
                            Total Video duration Limit{orgPresent ? "/Org" : ""}{" "}
                            in mins
                          </label>
                          <CustomInput
                            type="number"
                            name="video_duration"
                            id="video_duration"
                            formError={formError}
                            placeholder="Total Video Duration"
                            value={formValue.features.video_duration}
                            onChange={(e) =>
                              handleInputChange(
                                "features.video_duration",
                                Number(e.target.value)
                              )
                            }
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          >
                            Viewer Limit Per {orgPresent ? "Org" : "User"}
                          </label>
                          <CustomInput
                            type="number"
                            name="viewerLimitPerUser"
                            id="viewerLimitPerUser"
                            placeholder=" Viewer Limit Per User"
                            formError={formError}
                            value={formValue.features.viewerLimit}
                            onChange={(e) =>
                              handleInputChange(
                                "features.viewerLimit",
                                Number(e.target.value)
                              )
                            }
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="editorLimitPerUser"
                            className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          >
                            Editor Limit Per {orgPresent ? "Org" : "User"}
                          </label>
                          <CustomInput
                            type="number"
                            name="editorLimitPerUser"
                            id="editorLimitPerUser"
                            placeholder="Editor Limit Per User"
                            formError={formError}
                            value={formValue.features.editorLimit}
                            onChange={(e) =>
                              handleInputChange(
                                "features.editorLimit",
                                Number(e.target.value)
                              )
                            }
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="chatMsgLimitPerVideo"
                            className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          >
                            Chat Msg Limit Per Video{" "}
                            {orgPresent ? "/ Org" : "/ User"}
                          </label>
                          <CustomInput
                            type="number"
                            name="chatMsgLimitPerVideo"
                            id="chatMsgLimitPerVideo"
                            placeholder="Chat Msg Limit/Video"
                            formError={formError}
                            value={formValue.features.chatMsgLimitPerVideo}
                            onChange={(e) =>
                              handleInputChange(
                                "features.chatMsgLimitPerVideo",
                                Number(e.target.value)
                              )
                            }
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="maxRegreatePerVideo"
                            className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          >
                            Max Regenerate Summary / Video
                            {orgPresent ? "/ Org" : "/ User"}
                          </label>
                          <CustomInput
                            type="number"
                            name="maxRegreatePerVideo"
                            id="maxRegreatePerVideo"
                            placeholder="Max Regenerate Per Video"
                            formError={formError}
                            value={formValue.features.maxRegreatePerVideo}
                            onChange={(e) =>
                              handleInputChange(
                                "features.maxRegreatePerVideo",
                                Number(e.target.value)
                              )
                            }
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="maxRegreateChapterPerVideo"
                            className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          >
                            Max Regenerate Chapter/Video
                            {orgPresent ? "/Org" : ""}
                          </label>
                          <CustomInput
                            type="number"
                            name="maxRegreateChapterPerVideo"
                            id="maxRegreateChapterPerVideo"
                            placeholder="Max Regenerate Chapter/Video"
                            formError={formError}
                            value={
                              formValue.features.maxRegreateChapterPerVideo
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "features.maxRegreateChapterPerVideo",
                                Number(e.target.value)
                              )
                            }
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="maxOrgsPerUser"
                            className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                          >
                            Save and Rerun Limit{" "}
                            {orgPresent ? "/ Org" : "/ User"}
                          </label>
                          <CustomInput
                            type="number"
                            name="saveAndRegenerate"
                            id="saveAndRegenerate"
                            placeholder="ASR Limit"
                            formError={formError}
                            value={formValue.features.saveAndRegenerate}
                            onChange={(e) =>
                              handleInputChange(
                                "features.saveAndRegenerate",
                                Number(e.target.value)
                              )
                            }
                          />
                        </div>
                        {!orgPresent ? (
                          <div>
                            <label
                              htmlFor="maxOrgsPerUser"
                              className="block text-sm font-medium leading-6 text-gray-900 mb-2"
                            >
                              Max Orgs/User
                            </label>
                            <CustomInput
                              type="number"
                              name="maxOrgsPerUser"
                              id="maxOrgsPerUser"
                              placeholder="Max Orgs/User"
                              formError={formError}
                              value={formValue.features.maxOrgsPerUser}
                              onChange={(e) =>
                                handleInputChange(
                                  "features.maxOrgsPerUser",
                                  Number(e.target.value)
                                )
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="flex-shrink-0 border-t border-gray-200 mt-8 pt-4">
                        <div className="flex justify-end space-x-3">
                          <button
                            type="button"
                            className="rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </button>
                          <PrimaryButton
                            onClick={() => {
                              handleSave();
                            }}
                          >
                            Save
                          </PrimaryButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </main>
      </div>
    </>
  );
}

export default AddSubscriptionPage;
