export default function TranslateRequestButton({onTranslate, loading}){
    return  <button
    type="button"
    className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    onClick={() => onTranslate()}
  >
    {loading
      ? "Requesting..."
      : "Request for transcription"}
  </button>
}