import React from "react";
import ContentBox from "./ContentBox";
import CardLoader from "../../../components/Loader/CardLoader";

function UserSubscriptionList({ userSubscriptionsQuery }) {
  return (
    <div>
      {userSubscriptionsQuery?.isLoading
        ? [...Array(5).keys()].map((load) => (
            <CardLoader key={`CardLoader-${load}`} />
          ))
        : userSubscriptionsQuery?.data?.map((subscription) => {
            return (
              <ContentBox
                key={subscription._id}
                title={subscription.name}
                type={true}
                isOrgOrUser={false}
                id={subscription._id}
              />
            );
          })}
    </div>
  );
}

export default UserSubscriptionList;
