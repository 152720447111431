import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { uploadFile } from "../../../services/upload.services";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { sendCsvApi } from "../../../services/bulk-email.services";

const CSVModal = ({ isOpen, toggleModal, setCsvData }) => {
  const [file, setFile] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  const onFileChange = (file) => {
    setFile(file);
    const blob = new Blob([file], { type: file.type });
    // console.log("file", file);
    setFileLoading(true);
    uploadFile(blob, {
      contentType: file.type,
      ext: `.${file.name.split(".").pop()}`,
    })
      .then((filename) => {
        // console.log("File uploaded to: " + filename);
        setFileUrl(filename);
      })
      .finally(() => setFileLoading(false));
  };

  const handleUpload = () => {
    const payload = {
      url: fileUrl,
    };
    sendCSVMutation.mutate(payload);
  };

  const sendCSVMutation = useMutation({
    mutationFn: sendCsvApi,
    onSuccess: (data) => {
      setCsvData(data);
      toast.success("CSV uploaded, Please select template to send emails");
      toggleModal();
      // console.log(data);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={toggleModal}
      >
        <div className="flex items-center justify-center min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-lg ">
              <div className="flex flex-col items-center pb-4">
                Upload CSV with name and email of Users
              </div>

              <div
                className="flex flex-col items-center justify-center"
                // onClick={openFileInput}
                onDragEnter={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const droppedFile = e.dataTransfer.files[0];
                  if (droppedFile) {
                    onFileChange(droppedFile);
                  }
                }}
              >
                <label
                  htmlFor="csvFileInput"
                  className={`
                              ${
                                file
                                  ? "border-dashed border-[#70cba2] dark:border-[#70cba2] hover:border-[#70cba2]"
                                  : "border-dashed border-gray-300 dark:border-gray-600 hover-border-gray-500"
                              }
                              flex flex-col items-center justify-center w-72 border-2 rounded-lg cursor-pointer bg-white py-4 px-6 
                            `}
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-xs text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">
                        {fileLoading
                          ? "Uploading..."
                          : file
                          ? `Selected: ${file.name}`
                          : "Click to upload or drag and drop"}
                      </span>{" "}
                    </p>
                    {!file && (
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        CSV
                      </p>
                    )}
                  </div>
                  <input
                    type={"file"}
                    id={"csvFileInput"}
                    className="hidden"
                    accept={".csv"}
                    onChange={(e) => onFileChange(e.target.files[0])}
                  />
                </label>
              </div>
              <div>
                <button
                  type="button"
                  className="mt-8 mx-auto flex rounded-md border border-transparent bg-[#70cba2] px-8 py-2 text-base font-medium text-white hover:bg-[#5fac89] disabled:cursor-not-allowed"
                  onClick={() => handleUpload()}
                >
                  Upload
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CSVModal;
