import { Dialog, Transition } from "@headlessui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Fragment, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { preciseSummaryApi } from "../../../../services/video.services";

function WordCount(str) {
  return str.split(' ')
         .filter(function(n) { return n != '' })
         .length;
}

export default function PreciseSummaryModal({ open, setOpen, summary = "", videoID }) {
  const queryClient = useQueryClient();
  const cancelButtonRef = useRef(null);
  const [summaryRange, setSummaryRange] = useState(summary.length / 2);

  useEffect(() => {
    if (open) setSummaryRange((WordCount(summary)/2).toFixed(0));
  }, [open]);

  const handleRun = () => {
    preciseSummaryMutation.mutate({
      id: videoID,
      word_limit: parseInt(summaryRange),
    })
  };

  const preciseSummaryMutation = useMutation({
    mutationFn: preciseSummaryApi,
    onSuccess: (data) => {
      setOpen(false);
      toast.success(data.msg);
      const currentVideo = queryClient.getQueryData(["getVideoDetail", videoID]);

      queryClient.setQueryData(["getVideoDetail", videoID], {...currentVideo, tasks: data.data.tasks}); 
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full md:w-1/2">
                <div className="bg-white px-4 pb-4 pt-5 md:p-6 md:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Generated Summary{" "}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{summary}</p>
                      </div>

                      <div className="mt-2">
                        <div class="relative mb-6">
                          <label for="labels-range-input" class="sr-only">
                            Labels range
                          </label>
                          <input
                            id="labels-range-input"
                            type="range"
                            value={summaryRange}
                            min={(WordCount(summary)/3).toFixed(0)}
                            step={5}
                            max={WordCount(summary)}
                            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                            onChange={(e) => setSummaryRange(e.target.value)}
                          />
                          <span class="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">
                            Min ({(WordCount(summary)/3).toFixed(0)}) words
                          </span>
                          <span class="text-sm text-gray-500 dark:text-gray-400 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">{summaryRange} words</span>
                          <span class="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">
                            Max ({WordCount(summary)}) words
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-[#70cba2] px-3 py-2 text-sm font-semibold text-white shadow-smsm:ml-3 sm:w-auto"
                    onClick={() => handleRun()}
                    disabled={preciseSummaryMutation.isLoading}
                  >
                    {preciseSummaryMutation.isLoading ? "Running" : "Run"}
                  </button>
                  <button
                    type="button"
                    className="mt-3inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
