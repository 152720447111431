import { useQuery } from "@tanstack/react-query";
import AppBar from "../../components/AppBar/AppBar";
import ChatSection from "../../components/ChatSection";
import LeftSection from "./components/LeftSection";
import { getVideosApi } from "../../services/video.services";
import { toast } from "react-hot-toast";
import { useUserStore } from "../../store/user";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { getUserData } from "../../services/user.services";
import Footer from "../../components/Footer/Footer";


export default function VideoPage() {
  // const videoID = "12345"

  // const getVideoDetailQuery = useQuery({
  //   queryKey: ["getVideoDetail", videoID],
  //   enabled: false,
  //   staleTime: Infinity,
  //   queryFn: () => null,
  //   onSuccess: (data) => console.log("success", data),
  //   onError: (err)=> toast.error(err.message)
  // });

  const contentTypes = "PRODUCT_DEMOS,PRODUCT_LAUNCH,CLIENT_CONVERSATION,GENERAL"
  const getAllVideosQuery = useQuery({
    queryKey: ["getVideos", contentTypes],
    // enabled: false,
    staleTime: 60 * 1000,
    queryFn: () => getVideosApi({contentTypes}),
    // onSuccess: (data) => console.log("success", data),
    onError: (err) => toast.error(err.message),
  });

  

  return (
    <>
      {" "}
      <div className="min-h-full">
        <AppBar />

        <main className="pt-8 pb-8 bg-[#e4f5ed] min-h-screen">
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="sr-only">Page title</h1>
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-1 lg:gap-8 ">
              {/* Left column */}
              <div className="grid grid-cols-1 gap-4 lg:col-span-2 h-full">
                <section aria-labelledby="section-1-title">
                  <h2 className="sr-only" id="section-1-title">
                    Video section
                  </h2>
                  <LeftSection
                    getAllVideosQuery={getAllVideosQuery}
                    queryContentTypes={contentTypes}
                    // youtubeID={youtubeID}
                    // getVideoDetailQuery={getVideoDetailQuery}
                  />
                </section>
              </div>
             
            </div>
              <Footer />
          </div>
        </main>
      </div>
    </>
  );
}
