import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";

export const useDataQuery = (queryKey, queryFn, isEnable = true) => {
  return useQuery({
    queryKey: [...queryKey],
    staleTime: 1000 * 60,
    queryFn: queryFn,
    enabled: isEnable,
    onSuccess: (data) => {
      // console.log("success", data, queryKey);
    },
    onError: (err) => toast.error(err.message),
  });
};
