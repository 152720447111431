import axios from "axios";
import authHeader from "./auth-header";
import { autoLogout } from "../utils/logout";
import globalParams from "./global-params";

const BASE_URL3 = process.env.REACT_APP_BACKEND_URL3;

export const uploadVideoUrlApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL3 + "/video", data, {
      headers: { ...authHeader() },
      params: { ...globalParams() },
    });
    console.log(res.data);
    if (!res.data.success) {
      throw res.data;
    }
    return res.data;
  } catch (e) {
    // autoLogout(e)
    throw Error(e.response?.data.msg ?? e.msg ?? "Something went wrong");
  }
};

export const getVideoDetailApi = async (videoID) => {
  try {
    const res = await axios.get(BASE_URL3 + "/video/" + videoID, {
      headers: { ...authHeader() },
      params: { ...globalParams() },
    });
    return res.data.data;
  } catch (e) {
    // autoLogout(e)
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};
export const getVideosApi = async ({ contentTypes }) => {
  try {
    const res = await axios.get(BASE_URL3 + "/video", {
      headers: { ...authHeader() },
      params: {
        content_type: contentTypes,
        ...globalParams(),
      },
    });

    return res.data.data;
  } catch (e) {
    // autoLogout(e)
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const preciseChaptersApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL3 + "/video/precise-chapters", data, {
      headers: { ...authHeader() },
      params: { ...globalParams() },
    });
    return res.data;
  } catch (e) {
    // autoLogout(e)
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const preciseSummaryApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL3 + "/video/precise-summary", data, {
      headers: { ...authHeader() },
      params: { ...globalParams() },
    });
    return res.data;
  } catch (e) {
    // autoLogout(e)
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const regenerateSummaryChaptersApi = async (data) => {
  try {
    const res = await axios.post(BASE_URL3 + "/video/regenerate", data, {
      headers: { ...authHeader() },
      params: { ...globalParams() },
    });
    if (!res.data.success) {
      throw res.data;
    }
    return res.data;
  } catch (e) {
    // autoLogout(e)
    throw Error(e.response?.data.msg ?? e.msg ?? "Something went wrong");
  }
};

export const getAsrDetailApi = async (videoID) => {
  try {
    const res = await axios.get(
      BASE_URL3 + "/video/" + videoID + "/asr-details",
      {
        headers: { ...authHeader() },
        params: { ...globalParams() },
      }
    );
    return res.data.data;
  } catch (e) {
    // autoLogout(e)
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const updateAsrDetailApi = async ({ videoID, data }) => {
  try {
    const res = await axios.put(
      `${BASE_URL3}/video/${videoID}/asr-details`,
      data,
      {
        headers: { ...authHeader() },
        params: { ...globalParams() },
      }
    );
    if (!res.data.success) {
      throw res.data;
    }

    return res.data;
  } catch (e) {
    // autoLogout(e)
    throw Error(e.response?.data.msg ?? e.msg ?? "Something went wrong");
  }
};

export const translateSummaryApi = async ({ videoID, lang_type }) => {
  try {
    const res = await axios.post(
      `${BASE_URL3}/video/translate-summary`,
      { id: videoID, lang_type },
      {
        headers: { ...authHeader() },
        params: { ...globalParams() },
      }
    );
    return res.data;
  } catch (e) {
    // autoLogout(e)
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};

export const deleteVideoApi = async (id) => {
  try {
    const response = await axios.delete(BASE_URL3 + `/video/${id}`, {
      params: { ...globalParams() },
      headers: { ...authHeader() },
    });
    return response.data;
  } catch (e) {
    throw Error(e.response?.data.msg ?? "Something went wrong");
  }
};
