import { useModalStore } from "../../store/modal";

export default function TranscriptButton() {
  const setTranscriptionModal = useModalStore(
    (state) => state.setTranscriptionModal
  );
  return (
    <button
      type="button"
      className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-xs text-[#64748B] shadow-sm border border-[#64748B] hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      onClick={() => setTranscriptionModal(true)}
    >
      Transcript
    </button>
  );
}
