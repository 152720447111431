import Validator from "validatorjs";
import { organizationRule, subscriptionRule } from "./rule";

export const organizationAllValidation = (data) => {
  const validation = new Validator(data, organizationRule);
  const validationResponse = { isValid: validation.passes() };
  if (!validationResponse.isValid) {
    validationResponse.error = validation.errors.all();
  }
  return validationResponse;
};

export const subscriptionAllValidation = (data) => {
  const validation = new Validator(data, subscriptionRule);
  const validationResponse = { isValid: validation.passes() };
  if (!validationResponse.isValid) {
    validationResponse.error = validation.errors.all();
  }
  return validationResponse;
};
