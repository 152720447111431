import { useEffect, useState } from "react";

export default function TextSegment({ segment, onUpdate }) {
  const handleTextChange = (event) => {
    const newText = event.target.value;
    onUpdate(segment.id, newText);
  };

  return (
    <div className="mt-2">
      <div className="flex items-center justify-between">

     <p className="mt-1 text-xs leading-6 text-gray-700">Start: {segment.start} sec</p>
     <p className="mt-1 text-xs leading-6 text-gray-700">End: {segment.end} sec</p>
      </div>
    <textarea
      value={segment.text}
      onChange={handleTextChange}
      rows={2}
      cols={50}
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-400 sm:text-sm sm:leading-6 p-2 outline-none"
    />
    </ div>
  );
}
