import { useUserStore } from "../../store/user";
import { Fragment, useEffect, useState } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { handleSignout } from "../../utils/logout";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function AppBar() {
  const userDetail = useUserStore((state) => state.userDetail);
  const userNavigation = [
    { name: userDetail?.name ?? "Your Profile", href: "/profiles" },
    { name: "Organizations", href: "/organizations" },
    // { name: "Settings", href: "#" },
    { name: "Sign out", href: "signout" },
  ];
  const currentOrg = localStorage.getItem("audino-ai-org");
  const [navigation, setNavigation] = useState([
    { name: "Features", href: "/features", current: true },
    { name: "Content AI", href: "/content-ai", current: false },
    { name: "Meetings AI", href: "/meetings-ai", current: false },
    { name: "Call Recording AI", href: "/call-recording-ai", current: false },
    { name: "Hate Speech AI", href: "/hate-speech-ai", current: false },
    // { name: "Subscription", href: "/subscriptions", current: false },
    // { name: "Text to Audio", href: "/texts", current: false },
  ]);

  // const handleSignout = () => {
  //   localStorage.removeItem("audino-ai-token");
  //   // navigate("/");
  //   window.location.reload();
  // };

  useEffect(() => {
    if (userDetail.userType === "a") {
      setNavigation((prev) => [
        ...prev,
        { name: "Subscription", href: "/subscriptions", current: false },
        { name: "Users", href: "/users", current: false },
      ]);
    }
  }, [userDetail.userType]);

  return (
    <Popover as="header" className="">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="relative flex items-center justify-center py-5 lg:justify-between">
              {/* Logo */}
              <div className="absolute left-0 flex-shrink-0 lg:static flex">
                <NavLink to="/">
                  <span className="sr-only">Audino</span>
                  <img
                    className="h-8 w-auto"
                    src={require("../../assets/logos/logo.png")}
                    alt="Audino"
                  />
                </NavLink>
                <div className="hidden lg:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map((item, index) => (
                      <NavLink
                        key={`${item.href}${index}`}
                        to={item.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-[#70cba2] text-white"
                              : "text-[#70cba2] hover:bg-[#70cba2] hover:text-white hover:bg-opacity-75",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )
                        }
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>

              {/* Right section on desktop */}
              <div className="hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5">
                {/* <button
                  type="button"
                  className="relative flex-shrink-0 rounded-full p-1 text-[#70cba2] hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-4 flex-shrink-0">
                  <div>
                    <Menu.Button className="relative flex rounded-full  text-sm ring-2ring-opacity-20 ">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={userDetail.picture}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute -right-2 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) =>
                            item.href === "signout" ? (
                              <div
                                onClick={handleSignout}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                )}
                              >
                                {item.name}
                              </div>
                            ) : item.href === "/organizations" ? (
                              <NavLink
                                to={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}{" "}
                                <span className="text-[#70cba2]">
                                  {currentOrg ? `(${currentOrg})` : ``}
                                </span>
                              </NavLink>
                            ) : (
                              <NavLink
                                to={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </NavLink>
                            )
                          }
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>

              {/* Menu button */}
              <div className="absolute right-0 flex-shrink-0 lg:hidden">
                {/* Mobile menu button */}
                <Popover.Button className="relative inline-flex items-center justify-center rounded-md bg-transparent p-2 text-[#70cba2]  hover:bg-opacity-10  focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Popover.Button>
              </div>
            </div>
          </div>

          <Transition.Root as={Fragment}>
            <div className="lg:hidden">
              <Transition.Child
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="duration-150 ease-in"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Popover.Overlay className="fixed inset-0 z-20 bg-black bg-opacity-25" />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-150 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  className="absolute inset-x-0 top-0 z-30 mx-auto w-full max-w-3xl origin-top transform p-2 transition"
                >
                  <div className="divide-y divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="pb-2 pt-3">
                      <div className="flex items-center justify-between px-4">
                        <div>
                          <img
                            className="h-8 w-auto"
                            src={require("../../assets/logos/logo.png")}
                            alt="Your Company"
                          />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#70cba2]">
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                    </div>
                    <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                      {navigation.map((item, index) => (
                        <NavLink
                          key={`${item.href}${index}`}
                          to={item.href} // Replace 'item.path' with the path you want to link to
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? "bg-[#70cba2] text-white"
                                : "text-[#70cba2] hover:bg-[#70cba2] hover:text-white hover:bg-opacity-75",
                              "block rounded-md px-3 py-2 text-base font-medium"
                            )
                          }
                          activeClassName="bg-[#70cba2] text-white"
                          exact={true} // Use 'exact' to match the route exactly
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                    <div className="pb-2 pt-4">
                      <div className="flex items-center px-5">
                        <div className="flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={userDetail.picture}
                            alt=""
                          />
                        </div>
                        <div className="ml-3 min-w-0 flex-1">
                          <div className="truncate text-base font-medium text-gray-800">
                            {userDetail.name}
                          </div>
                          <div className="truncate text-sm font-medium text-gray-500">
                            {userDetail.email}
                          </div>
                        </div>
                        {/* <button
                          type="button"
                          className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[#70cba2] focus:ring-offset-2"
                        >
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button> */}
                      </div>
                      <div className="mt-3 space-y-1 px-2">
                        {userNavigation.map((item) =>
                          item.href === "signout" ? (
                            <div
                              key={item.name}
                              onClick={handleSignout}
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                            >
                              {item.name}
                            </div>
                          ) : (
                            <NavLink
                              key={item.name}
                              to={item.href}
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                            >
                              {item.name}
                            </NavLink>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition.Child>
            </div>
          </Transition.Root>
        </>
      )}
    </Popover>
  );
}
