import React from "react";
import ContentBox from "./ContentBox";
import CardLoader from "../../../components/Loader/CardLoader";
import Pagination from "./Pagination";

export default function UsersList({
  userSubscriptionsQuery,
  usersQuery,
  page,
  setPage,
}) {
  return (
    <div>
      {usersQuery?.isLoading || userSubscriptionsQuery?.isLoading
        ? [...Array(5).keys()].map((load) => (
            <CardLoader key={`CardLoader-${load}`} />
          ))
        : usersQuery.data?.data.map((user) => {
            return (
              <ContentBox
                key={user._id}
                title={user.name}
                email={user.email}
                type={false}
                isOrgOrUser={false}
                id={user._id}
                subOptions={userSubscriptionsQuery?.data}
                selectedSubscription={user.subscriptions}
              />
            );
          })}

      <Pagination
        totalPages={usersQuery?.data?.totalPages}
        setPage={setPage}
        page={page}
      />
    </div>
  );
}
