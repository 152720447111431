import { useState, Fragment, useId, useRef, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";

import { FaceSmileIcon, PaperClipIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useVideoStore } from "../store/video";
import dayjs from "dayjs";
import { useUserStore } from "../store/user";
import {
  fetchAllVideoMsgsApi,
  postAudioCommentApi,
  postCommentApi,
} from "../services/msg.services";
import { toast } from "react-hot-toast";
import PaymentPopup from "./PaymentPopup";
import { ReactComponent as EmptyMsg } from "../assets/svgs/emptyMsg.svg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ChatSection({ sectionType, getAllMsgsQuery, value }) {
  const [inputValue, setInputValue] = useState("");
  const [isPaymentPopup, setIsPaymentPopup] = useState(false);
  const msgContainerRef = useRef(null);

  // generate unique ID
  const relativeTime = require("dayjs/plugin/relativeTime"); // Import the plugin
  dayjs.extend(relativeTime); // Extend Day.js with the plugin

  const chatActivity = useVideoStore((state) => state.chatActivity);
  const setChatActivity = useVideoStore((state) => state.setChatActivity);
  const userDetail = useUserStore((state) => state.userDetail);

  const addVideoCommentMutation = useMutation({
    mutationFn: postCommentApi,
  });

  // const addAudioCommentMutation = useMutation({
  //   mutationFn: postAudioCommentApi,
  // });

  const handlePostComment = (event) => {
    event.preventDefault();

    // if (sectionType === "video") {
    addVideoCommentMutation.mutate(
      {
        query: inputValue,
        ...value,
      },
      {
        onSuccess: (data) => {
          toast.success(data.msg);
          setChatActivity([...chatActivity, ...data.data]);
        },
        onError: (err) => {
          toast.error(err.message);
        },
        onSettled: () => setInputValue(""),
      }
    );
    // } else if (sectionType === "audio") {
    //   addAudioCommentMutation.mutate(
    //     {
    //       msg: inputValue,
    //       msg_from: userDetail.user_id,
    //       ...value,
    //     },
    //     {
    //       onSuccess: (data) => {
    //         toast.success(data.msg);
    //         setChatActivity([...chatActivity, ...data.data]);
    //       },
    //       onError: (err) => {
    //         toast.error(err.message);
    //       },
    //       onSettled: () => setInputValue(""),
    //     }
    //   );
    // }
  };

  return (
    <>
      <div className="lg:col-start-3">
        {/* Activity feed */}
        <h2 className="text-sm font-semibold leading-6 text-gray-900">
          Activity
        </h2>
        <div className="flex flex-col h-[calc(100vh-12rem)] overflow-hidden mt-6 ">
          <ul
            className="flex-grow overflow-y-auto space-y-4"
            ref={msgContainerRef}
          >
            {getAllMsgsQuery.isLoading || getAllMsgsQuery.isRefetching ? (
              <>
                {[...Array(5).keys()].map((e) => (
                  <div
                    className="flex-auto rounded-md animate-pulse mt-2"
                    key={`chatloading-${e}`}
                  >
                    <div className="w-full h-12 bg-[#70cba2] rounded-md"></div>
                  </div>
                ))}
              </>
            ) : chatActivity.length > 0 ? (
              chatActivity.map((activityItem, activityItemIdx) => (
                <li
                  key={activityItem.chat_id}
                  className="relative flex gap-x-4"
                >
                  <div
                    className={classNames(
                      activityItemIdx === chatActivity.length - 1
                        ? "h-6"
                        : "-bottom-6",
                      "absolute left-0 top-0 flex w-6 justify-center"
                    )}
                  >
                    <div className="w-px bg-gray-200" />
                  </div>
                  {/* {activityItem?.msg_from?.username ? ( */}
                  <>
                    {activityItem?.msg_from?.picture ? (
                      <img
                        src={activityItem?.msg_from?.picture}
                        alt=""
                        className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                      />
                    ) : (
                      <img
                        src={require("../assets/logos/logo_icon.png")}
                        alt=""
                        className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                      />
                    )}
                    <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                      <div className="flex justify-between gap-x-4">
                        <div className="py-0.5 text-xs leading-5 text-gray-500">
                          {activityItem?.msg_from?.name ? (
                            <>
                              {" "}
                              <span className="font-medium text-gray-900">
                                {activityItem?.msg_from?.name}
                              </span>{" "}
                              commented
                            </>
                          ) : (
                            <>
                              {" "}
                              <span className="font-medium text-gray-900">
                                Audino
                              </span>{" "}
                              replied
                            </>
                          )}
                        </div>
                        <time
                          dateTime={activityItem?.createdAt}
                          className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                        >
                          {dayjs(activityItem?.createdAt).fromNow()}
                        </time>
                      </div>
                      <p className="text-sm leading-6 text-gray-500">
                        {activityItem?.message}
                      </p>
                    </div>
                  </>
                  {/* // ) : (
                //   <>
                //     <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                //       <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                //     </div>
                //     <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                //       <span className="font-medium text-gray-900">
                //      Audino 
                //       </span>{" "}
                //       replied the chat.
                //     </p>
                //     <time
                //       dateTime={activityItem.created_at}
                //       className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                //     >
                //     {dayjs(activityItem?.created_at).fromNow()} 
                //     </time>
                //   </>
                // )} */}
                </li>
              ))
            ) : (
              <div className="flex h-full">
                <EmptyMsg />
              </div>
            )}
            {addVideoCommentMutation.isLoading && (
              <li className="relative flex gap-x-4">
                <img
                  src={require("../assets/logos/logo_icon.png")}
                  alt=""
                  className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                />
                <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 animate-pulse">
                  <div className="w-full h-12 bg-[#70cba2] rounded-md"></div>
                </div>
              </li>
            )}
          </ul>

          {/* New comment form */}
          {/* {chatActivity.length <= 10 && ( */}
          <div className="flex gap-x-3 mt-3">
            <img
              src={userDetail.picture}
              alt=""
              className="h-6 w-6 flex-none rounded-full bg-gray-50"
            />
            <form
              onSubmit={(e) => handlePostComment(e)}
              className="relative flex-auto"
            >
              <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-400">
                <label htmlFor="comment" className="sr-only">
                  Add your question
                </label>
                <textarea
                  rows={2}
                  name="comment"
                  id="comment"
                  className="block w-full resize-none border-0 bg-transparent py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 !outline-none sm:text-sm sm:leading-6"
                  placeholder="Add your question..."
                  defaultValue={""}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      // Prevent the default behavior (newline) and submit the form
                      handlePostComment(e);
                    }
                  }}
                />
              </div>

              <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                <div className="flex items-center space-x-5">
                  <div className="flex items-center">
                    <button
                      type="button"
                      className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                    >
                      <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                      <span className="sr-only">Attach a file</span>
                    </button>
                  </div>
                  <div className="flex items-center">
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <FaceSmileIcon
                          className="h-5 w-5 flex-shrink-0"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Add your mood</span>
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="rounded-md bg-white px-2.5 py-1.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  disabled={
                    inputValue === "" || addVideoCommentMutation.isLoading
                  }
                >
                  {addVideoCommentMutation.isLoading ? "Sending..." : "Ask"}
                </button>
              </div>
            </form>
          </div>
          {/* )} */}
        </div>
      </div>
      <PaymentPopup isOpen={isPaymentPopup} setIsOpen={setIsPaymentPopup} />
    </>
  );
}
