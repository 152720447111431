import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { postContactRequestApi } from "../../services/contact.services";
import { toast } from "react-hot-toast";
import { useUserStore } from "../../store/user";
const defaultValue = {
  email: "",
  message: "",
};
export default function Footer() {
  const userDetail = useUserStore((state) => state.userDetail);
  const [formData, setFormData] = useState({ ...defaultValue, email: userDetail.email });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addContactRequestMutation = useMutation({
    mutationFn: postContactRequestApi,
    onSuccess: (data) => {
      toast.success(data.msg);
      setFormData(defaultValue);
    },
    onError: (err) => {
      toast.error(err.message);
      setFormData(defaultValue);
    },
  });

  return (
    <div className="bg-white shadow sm:rounded-lg mt-8">
      <div className="px-4 py-5 sm:p-6 md:flex justify-between items-center">
        <div className="max-w-xl">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Have Questions?
          </h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>We're just a message away. Ask us anything you need to know.</p>
          </div>
          <p className="my-4 text-sm text-gray-500 text-center italic">or</p>
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Reach Our Team
          </h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              Contact No: <a href="tel:+91 88009 66082">+91 88009 66082</a>
              <br /> Mail Id:{" "}
              <a href="mailto:contact@audino.in"> contact@audino.in</a>
            </p>
          </div>
        </div>
        <form className="md:mt-0 mt-5  w-full sm:max-w-md">
          <div className="">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400  ring-[#70cba2] sm:text-sm sm:leading-6"
              placeholder="you@example.com"
              onChange={handleInputChange}
            />
          </div>
          <div className="mt-2">
            <label htmlFor="message" className="sr-only">
              Message
            </label>
            <textarea
              rows={4}
              name="message"
              id="message"
              value={formData.message}
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 ring-[#70cba2] sm:text-sm sm:leading-6"
              placeholder="I have a question about ..."
              onChange={handleInputChange}
            />
          </div>
          <button
            type="submit"
            className="w-full mt-3 inline-flex items-center justify-center rounded-md bg-[#70cba2] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#70cba2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#70cba2]"
            disabled={
              formData.email === "" ||
              formData.message === "" ||
              addContactRequestMutation.isLoading
            }
            onClick={(e) => {
              e.preventDefault();
              addContactRequestMutation.mutate(formData);
            }}
          >
            {addContactRequestMutation.isLoading ? "Sending..." : "Send"}
          </button>
        </form>
      </div>
    </div>
  );
}
