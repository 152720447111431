import { VideoCameraIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { Fragment, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadVideoUrlApi } from "../../../services/video.services";
import { toast } from "react-hot-toast";
import { NavLink } from "react-router-dom";
// import UnstructureImage from "../../../assets/images/unstructure.png";
import AudioSummary from "../../../assets/images/audio-summary.png";
import VideoSummary from "../../../assets/images/video-summary.png"
import SpeechToText from "../../../assets/images/speech-to-text.png"
import TextToSpeech from "../../../assets/images/text-to-speech.png"
import Meetings from "../../../assets/images/meeting.png"
import CallCenter from "../../../assets/images/phone-call.png"


export default function LeftSection({}) {
  const features = [
    {
      name: 'Content AI',
      title: 'Convert YouTube Videos, Public Audio, or Video URLs into interactive discussions',
      roles: ['Video', 'Audio', 'Summary', 'Youtube', 'Chat'],
      imageUrl:
        VideoSummary,
        to: "/content-ai",
    },
    {
      name: 'Meetings AI',
      title: 'Convert Meetings Videos, Auidos, or Video URLs into interactive discussions',
      roles: ['Video', 'Audio', 'Summary', 'Meetins', 'Chat'],
      imageUrl:
        Meetings,
        to: "/meetings-ai",
    },
    {
      name: 'Call Recording AI',
      title: 'Convert Call Recording Videos, Auidos, or Video URLs into interactive discussions',
      roles: ['Video', 'Audio', 'Summary', 'Call', 'Chat'],
      imageUrl:
        CallCenter,
        to: "/call-recording-ai",
    },
    {
      name: 'Hate Speech AI',
      title: "Detect hate speech in videos and audios",
      roles: ['Video', 'Audio', 'Summary', 'Hate', 'Chat'],
      imageUrl:
        AudioSummary,
        to: "/hate-speech-ai",
    },
    // {
    //   name: 'Audio To Text',
    //   title: 'Transcribe your audio files with AI',
    //   roles: ['Audio', 'Summary', 'Youtube', 'STT'],
    //   imageUrl:
    //   SpeechToText,
    //     to: "/audios",
    // },
    // {
    //   name: 'Text To Audio',
    //   title: 'Convert text to audio in different languages and voices',
    //   roles: ['Text', 'AI', 'TTS', 'Audio', 'Transcript'],
    //   imageUrl:
    //   TextToSpeech,
    //     to: "/texts",
    // }
  ]
  
  return (
    <div className="overflow-hidden rounded-lg bg-white shadow  p-6">
      <h2 className="sr-only">Videos</h2>

      <h2 className="truncate text-2xl font-bold text-gray-700">
        Audino AI Features
      </h2>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-8"
      >
        {features.map((feature) => (
          <li
            key={feature.email}
            className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
          >
            <div className="flex flex-1 flex-col p-8">
              <img
                className="mx-auto h-32 w-32 flex-shrink-0 rounded-full object-contain"
                src={feature.imageUrl}
                alt=""
              />
              <h3 className="mt-6 text-sm font-medium text-gray-900">
                {feature.name}
              </h3>
              <dl className="mt-1 flex flex-grow flex-col justify-between">
                <dt className="sr-only">Title</dt>
                <dd className="text-sm text-gray-500">{feature.title}</dd>
                <dt className="sr-only">Role</dt>
                <dd className="mt-3 flex gap-2 flex-wrap justify-center">
                  {feature.roles.map((role) => (
                    <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {role}
                    </span>
                  ))}
                </dd>
              </dl>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="-ml-px flex w-0 flex-1">
                  <NavLink
                    to={`${feature.to}`}
                    className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                  >
                    Explore
                  </NavLink>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
